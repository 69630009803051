import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Data from "../Data.json"
import { NavLink } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { sideMenuData } from '../Data.jsx';


const FullPage = styled.div`
  width:100vw;
  min-height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position:relative;
background:white;

`
const Menu = styled(NavLink)`
  text-decoration:none;
  :visited,:link{
    color:#2b3cf6;
  }
  text-shadow: 0px 0px 3px #a5a5a5;
  cursor:pointer;
  transition:all 300ms;
  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`
const SubMenu = styled.div`
`;
const SideUl = styled.li`
  padding:0.5rem 0;
  direction:rtl;
  cursor:pointer;
  transition:all 300ms;
  color:black;
  list-style:none;
  text-shadow: none;
  color:${props => (props.head? "#2b3cf6" : "#414141")};
  text-shadow:${props => (props.head? "0px 0px 3px #a5a5a5" : "none")};

  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`
const ContentLink = ({activeContent,title,onClick,multiple=false,isOpen=false,submenus,name})=>{
  const [activeSub,setActiveSub] = useState(false)
  if(!multiple){
    return(
      <MenuContainer>
       <Menu head to={"content/"+name}><SideUl head onClick={()=>onClick(name)} active={activeContent===name}>{title}</SideUl></Menu>
      </MenuContainer>
    )
  }
  if(multiple){
    return(
        <MenuContainer>
       <Menu head to={"content/"+name}><SideUl head onClick={()=>onClick(name)} active={activeContent===name}>{title}</SideUl></Menu>
        <SubMenu active={activeSub}>
        {submenus.map((item)=>{
          return(<Menu to={"content/"+item.name}><SideUl onClick={()=>onClick(item.name)} active={activeContent===item.name}>{item.title}</SideUl></Menu>)
        })}
        </SubMenu>
        </MenuContainer>
    )
  }
  }

function SectionsPart(props){
return(
<FullPage>
<GridContainer>
{sideMenuData.map((item)=><ContentLink

title={item.title}
multiple={item.type==="multiple"}
submenus={item.content}
name={item.name}
/>)}
</GridContainer>
</FullPage>
)
}
export default SectionsPart;

const GridContainer = styled.div`

  display:grid;
  direction:rtl;
  width:100%;
  height: 100%;
  @media(max-width:760px){
    padding:3rem 0;
    grid-template-columns:1fr 1fr;
}
  grid-template-columns:1fr 1fr 1fr 1fr;
  font-size:1.5rem;
  padding:3rem;
`
const MenuContainer = styled.div`
  padding:1rem;
  box-sizing:border-box;
  @media(max-width:760px){
    padding-right:25%;
        grid-template-columns:1fr 1fr;
}
  padding-right:7rem;
`