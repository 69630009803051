import React, { useState } from 'react';
import styled from  '@emotion/styled';

import { NavLink } from 'react-router-dom';

const MenuContainer = styled.div`
  padding:1rem;
  box-sizing:border-box;
  padding-right:7rem;
`
const Menu = styled(NavLink)`
  text-decoration:none;
  :visited,:link{
    color:#2b3cf6;
  }
  text-shadow: 0px 0px 3px #a5a5a5;
  cursor:pointer;
  transition:all 300ms;
  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`
const SubMenu = styled.div`
`;
const SideUl = styled.li`
  padding:0.5rem 0;
  direction:rtl;
  cursor:pointer;
  transition:all 300ms;
  color:black;
  list-style:none;
  text-shadow: none;
  color:${props => (props.head? "#2b3cf6" : "#414141")};
  text-shadow:${props => (props.head? "0px 0px 3px #a5a5a5" : "none")};

  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`
export const ContentLink = ({activeContent,title,onClick,multiple=false,isOpen=false,submenus,name})=>{
        
       return <Menu head to={"content/"+name}><SideUl onClick={()=>onClick(name)} active={activeContent===name}>{title}</SideUl></Menu>

 
  }