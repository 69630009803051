import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Data from "../Data.json"
import { NavLink } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { FAQContent, sideMenuData } from '../Data.jsx';
import { getSwitchUnstyledUtilityClass } from '@mui/base';
import useWindowDimensions from '../Components/SizeDetector';


const FullPage = styled.div`
span{
  margin-top:7rem;
  font-size:2rem;
  display:block;
  font-weight:600;
  text-shadow: 0px 0px 3px #a5a5a5;
  text-align:center;


}
direction:rtl;
.wrapper {
  width: 100%;
  padding: 2rem ${props=>props.isMobile?"2rem":"10rem"};
  box-sizing:border-box;

}
.question::after{
  content: "+";
  font-size: 2.2rem;
  position: absolute;
  left: 20px;
  transition: 0.2s;
}
.question{
  text-align:right;
  font-size: 1.5rem;
  font-weight: 600;
  padding: ${props=>props.isMobile?"2rem 2rem 2rem 5rem":"2rem"};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  }
  .container {
  background-color: white;
  color: black;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgb(0,0,0,0.1);
  margin: 20px 0;
}
.question.active::after {
  transform: rotate(45deg);
}

.answercont {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}

.answer {
  padding: 0 20px 20px;
  line-height: 3rem;
  font-size:1.2rem;
}
.more-info{
  text-align: left;
  padding: 0 2rem 1rem;
  font-size:1.2rem;
  box-sizing:border-box;
  color:#2b3cf6;
  text-shadow: 0px 0px 3px #a5a5a5;
}

`

function FaqPart(props){
  let questions = document.querySelectorAll(".question");
  let answers = document.querySelectorAll(".answer");
  const { height, width } = useWindowDimensions();
  const firstFunc = (e)=>{
    let active = document.querySelector(".question.active");
    console.log(e)
    console.log(e.target.classList)
    if(!active){
      e.target.classList.add("active")
      active = e.target
      let last_answer = active.nextElementSibling;
      last_answer.style.maxHeight = last_answer.scrollHeight + "px";
    }
    else if(active===e.target){
      e.target.classList.remove("active")
      let last_answer = active.nextElementSibling;
      last_answer.style.maxHeight = 0;
    }
    else if(active!==e.target){
      let last_answer = active.nextElementSibling;
      last_answer.style.maxHeight = 0;
      active.classList.remove("active")
      e.target.classList.add("active")
      active = e.target
       last_answer = active.nextElementSibling;
      last_answer.style.maxHeight = last_answer.scrollHeight + "px";

    }

    
  }




return(
<FullPage id="FAQ" isMobile={width<760}>
  <span>  سوالات متداول</span>
<div className="wrapper">
  {FAQContent.map((item)=>{
    return <div className="container">
    <div className="question" onClick={(e)=>{firstFunc(e)}}>
           {item.question}
      </div>
      <div class="answercont">
        <div class="answer">
        {item.answer}
        </div>
        <div className="more-info">
          <Menu to={item.moreInfo}>
            <SideUl head>
              اطلاعات بیشتر
            </SideUl>
          </Menu>
        </div>
      </div>
    </div>
  })}

</div>

</FullPage>
)
}
export default FaqPart;


const Menu = styled(NavLink)`
  text-decoration:none;
  :visited,:link{
    color:#2b3cf6;
  }
  text-shadow: 0px 0px 3px #a5a5a5;
  cursor:pointer;
  transition:all 300ms;
  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`
const SideUl = styled.li`
  padding:0.5rem 0;
  direction:rtl;
  cursor:pointer;
  transition:all 300ms;
  color:black;
  list-style:none;
  text-shadow: none;
  color:${props => (props.head? "#2b3cf6" : "#414141")};
  text-shadow:${props => (props.head? "0px 0px 3px #a5a5a5" : "none")};

  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`