import { ContentData } from "./ContentData";
import ContentView from "./Contents/ContentView";
import TitleView from "./Contents/TitleView";

export const sideMenuData = [
    {view:<ContentView data={ContentData("about")}/>,name:"about",title:"درباره محصول",type:"single"},
    {view:<ContentView data={ContentData("begin")}/>,name:"begin",title:"مقدمه",type:"single"},
    {view:<ContentView data={ContentData("start")}/>,name:"start",title:"آغاز به کار",type:"single"},
    {view:<ContentView data={ContentData("dashboard")}/>,name:"dashboard",title:"پیشخوان فروشگاه",type:"single"},
	{name:"transactions",title:"تراکنش ها (کیف پول)",type:"multiple",content:[
        {view:<ContentView data={ContentData("transactionsState")}/>,name:"transactionsState",title:"وضعیت تراکنش"},
        {view:<ContentView data={ContentData("transactionsVariety")}/>,name:"transactionsVariety",title:"انواع تراکنش ها"},
        {view:<ContentView data={ContentData("depositRequest")}/>,name:"depositRequest",title:"درخواست واریز به حساب"}
    ]},
    {name:"messagesPage",title:"صفحه پیام ها",type:"multiple",content:[
        {view:<ContentView data={ContentData("newMessages")}/>,name:"newMessages",title:"پیام جدید"},
        {view:<ContentView data={ContentData("messages")}/>,name:"messages",title:"پیام ها"},
        {view:<ContentView data={ContentData("notifications")}/>,name:"notifications",title:"اعلانات"},
        {view:<ContentView data={ContentData("archive")}/>,name:"archive",title:"بایگانی"}
        ]},
    {name:"supplement",title:" الحاقیه ها",type:"multiple",content:[
        {view:<ContentView data={ContentData("goodSupplement")}/>,name:"goodSupplement",title:"الحاقیه کالا"},
        {view:<ContentView data={ContentData("otherSupplement")}/>,name:"otherSupplement",title:"سایر الحاقیه ها"}
        ]},
    {name:"contracts",title:" قرارداد ها",type:"multiple",content:[
        {view:"",name:"goodSupplement",title:"الحاقیه کالا"},
        {view:"",name:"otherSupplement",title:"سایر الحاقیه ها"}
    ]},
    {view:"",name:"orders",title:"سفارشات",type:"multiple",content:[
        {view:<ContentView data={ContentData("allOrders")}/>,name:"allOrders",title:"همه سفارشات"},
        {view:<ContentView data={ContentData("prepairingOrders")}/>,name:"prepairingOrders",title:"در حال آماده سازی"},
        {view:<ContentView data={ContentData("deliverdOrders")}/>,name:"deliverdOrders",title:"تحویل داده شده"},
        {view:<ContentView data={ContentData("returned")}/>,name:"returned",title:"مرجوعی"}
    ]},
    {name:"products",title:"محصولات",type:"multiple",content:[
        {view:<ContentView data={ContentData("editProduct")}/>,name:"editProduct",title:"ویرایش محصول"},
        {view:<ContentView data={ContentData("viewProductInfo")}/>,name:"viewProductInfo",title:"مشاهده اطلاعات محصول"}

    ]},
    {name:"commentsAndQuestions",title:"نظرات و سوالات",type:"multiple",content:[
        {view:<ContentView data={ContentData("comments")}/>,name:"comments",title:"نظرات"},
        {view:<ContentView data={ContentData("qa")}/>,name:"qa",title:"سوال و جواب"}

    ]},
    {view:<ContentView data={ContentData("financialReports")}/>,name:"financialReports",title:"گزارشات مالی",type:"single"},
	{view:<ContentView data={ContentData("newProduct")}/>,name:"newProduct",title:"افزودن محصول جدید",type:"single"},
    {view:<ContentView data={ContentData("buyers")}/>,name:"buyers",title:"خریداران",type:"single"},
    {view:<ContentView data={ContentData("settings")}/>,name:"settings",title:"تنظیمات",type:"single"},
    {view:<ContentView data={ContentData("help")}/>,name:"help",title:"راهنما",type:"single"}
]
export const FAQContent = [
	{
		question:"اطلاعات و مشخصات خریداران فروشگاه از کدام بخش قابل دسترسی می باشد؟",
		answer:"با کلیک روی دکمه خریداران در منوی سمت راست، وارد بخش خریداران می شوید. در این بخش اطلاعات مربوط به بهترین خریداران فروشگاه شما طبق تاریخ انتخابی قابل مشاهده می باشد.  این اطلاعات شامل نام  ، مبلغ خریداری شده، تاریخ اولین خرید، تاریخ آخرین خرید و آخرین محصول  خریداری شده توسط آنها می باشد که با زدن دکمه جستجو می توانید آنها را مشاهده کنید.",
		moreInfo:"content/buyers"
	},
		{
		question:"چگونه می توانیم گزارشات مالی و گزارشات فروش فروشگاه را مشاهده کنیم؟",
		answer:"با کلیک روی دکمه گزارشات مالی از منوی سمت راست پنل، وارد صفحه گزارشات مالی می شوید. در این قسمت گزارش مالی فروشگاه بر حسب تاریخ مورد نظر و یا به صورت روزانه، هفتگی، .... به صورت نمودار قابل مشاهده می باشد. همچنین در این بخش گزارش بازدیدهای اخیر، پرفروش ترین محصولات و کم فروش ترین محصولات و همچنین گزارش مشتریان جدید خود را نیز می توانید مشاهده نمایید. همچنین گزارش فروش بر حسب تاریخ، زیرگروه، برند و یا کدکالای مورد نظر نیز پس از کلیک روی دکمه جستجو قابل دسترسی می باشد.",
		moreInfo:"content/financialReports"
	},
		{
		question:"نظرات کاربران سایت از کدام قسمت قابل مشاهده است؟",
		answer:"در منوی سمت راست بخش نظرات شما می توانید کلیه نظرات کاربران سایت درمورد فروشگاه خود را مشاهده نمایید. همچنین با کلیک روی دکمه پرسش و پاسخ ، وارد صفحه پرسش و پاسخ می شوید. در این صفحه  شما می توانید با انتخاب تاریخ مورد نظر و سپس کلیک بر روی دکمه جستجو ، سوالات کاربران را مشاهده نمایید. همچنین با کلیک بر روی دکمه پاسخ میتوانید، به سوالهای کاربران پاسخ مناسب ارسال نمایید. ",
		moreInfo:"content/comments"
	},
		{
		question:"چگونه تصاویر محصولات فروشگاه خود را مشاهده و ویرایش کنم؟",
		answer:"جهت مشاهده  اطلاعات محصول، پس از کلیک بر روی دکمه دایره ای مربوط به محصول مورد نظر، گزینه مشاهده اطلاعات محصول را کلیک نمایید. در صفحه اطلاعات محصول کلیه اطلاعات محصول به صورت دسته بندی شده نمایش داده میشود که در صورت لزوم میتوانید در پایین صفحه و در قسمت تصاویر کالا، عکسهای مربوط به کالای خود را ویرایش نمایید. ",
		moreInfo:"content/editProduct"
	},
		{
		question:"قرارداد فروشگاه خود را از کدام بخش میتوانیم مشاهده و یا ویرایش کنیم؟",
		answer:"برای مشاهده قرارداد خود، از منوی سمت راست بخش قراردادها را انتخاب نموده و جزئیات قرارداد خود را مشاهده نمایید. تب های موجود در صفحه جزییات قرارداد شامل اطلاعات قرارداد، الحاقیه ها و سایر الحاقیه ها می باشند. توجه داشته باشید که تب های الحاقیه ها مربوط به الحاقیه کالاهای اضافه شده به سیستم بوده و تب سایر الحاقیه ها مربوط به انواع مختلفی از الحاقیه ها مانند تمدید قرارداد، تغییر قیمت قرارداد و .... می باشد که هر کدام قابلیت فیلتر شدن در لیست هایی موجود را دارند.",
		moreInfo:"content/contracts"
	},
		{
		question:"چگونه می توانیم پیامهای خود را بایگانی کنیم؟",
		answer:"برای بایگانی پیامهای خود وارد بخش پیامها،قسمت بایگانی شوید. این قسمت برای بایگانی اعلانات سیستم مورد استفاده قرار می گیرد. در قسمت اعلانات و بعد از مشاهده پیامها، گزینه ای برای بایگانی اعلانات وجود دارد که با کلیک بر روی آن، اعلان مورد نظر به قسمت بایگانی پیامها منتقل می گردد.",
		moreInfo:"content/messagesPage"
	},
		{
		question:"در بخش اعلانات پیامها چه اطلاعاتی قابل مشاهده است؟",
		answer:"پیام های اطلاع رسانی مانند تایید الحاقیه های قیمت ، خرید مشتریان از فروشگاه شما و ...  که از طرف پلتفرم لوکسین لوکس بصورت اتوماتیک تولید میشود، در این قسمت برای شما قابل مشاهده خواهد بود . شما میتوانید با انتخاب تاریخ مورد نظر (زدن دکمه های از تاریخ – تا تاریخ) و انتخاب موضوع پیام و سپس کلیک بر روی دکمه جستجو نتایج را  مشاهده نمود. ",
		moreInfo:"content/notifications"
	},
		{
		question:"اگر اطلاعات استاندارد کالای مورد نظر در لیست نباشد، چگونه محصول فروشگاهی اضافه کنیم؟",
		answer:"در فرآیند اضافه کردن محصول در هر قسمتی که  نیاز به اضافه کردن اطلاعات وارد نشده استاندارد، مانند گروههای کالایی، زیر گروهها، برند ها، در لیست های مورد نظر بود ،با زدن  دکمه + در کنار همان لیست ، صفحه ای باز می شود که با پر کردن آن میتوانید اطلاعات درخواستی خود را به کارشناسان لوکسین لوکس ارسال نمایید تا در اسرع وقت به سیستم اضافه نمایند. با این کار درخواست شما برای گروه پشتیبانی ارسال شده و در صورت تایید اطلاعات شما  به لیست اضافه خواهد شد. در مورد زیرگروه کالا و برند نیز فرآیند به همین صورت می باشد. ",
		moreInfo:"content/newProduct"
	},
		{
		question:"کاربرد صفحه پیشخوان فروشگاه چیست؟",
		answer:"در این صفحه شما می توانید محصول جدید اضافه کنید، پیام های جدید ارسال شده را ببینید و الحاقیه ها و قراردادهای خود را مشاهده نمایید. همچنین اطلاعات مالی فروشگاه، لیست خریداران، بازدید های اخیر و دیدگاههای کاربران نیز با یک نگاه، قابل مشاهده می باشد که با کلیک بر روی دکمه مشاهده به صفحه همان موضوع منتقل خواهید شد.",
		moreInfo:"content/begin"
	},
		{
		question:"در پنل مدیریت فروشگاه، چه گزارشاتی قابل مشاهده می باشد؟",
		answer:"در این پنل، گزارش مهمترین خریداران، دیدگاههای اخیر کاربران، بازدیدهای اخیر و کیف پول نمایش داده شده که با زدن دکمه مشاهده در زیر هر بخش، گزارش آن قابل دسترسی خواهد بود. همچنین در بالای صفحه، اطلاع رسانی های  مهم سیستم از جمله تعداد پیامهای جدید، تعداد سفارشات جدید، موجودیهای کالا های به حداقل رسیده ، الحاقیه های جدید و تعداد مرجوعی های جدید قابل مشاهده می باشدکه با کلیک بر روی هر کدام از موارد ذکر شده به صفحه همان موارد ارجاع داده می شوید.",
		moreInfo:"content/begin"
	},
		{
		question:"چگونه به پنل مدیریت فروشگاه خود وارد شویم؟",
		answer:"برای ورود به پنل فروشگاه از طریق صفحه کاربری خود، از منوی باز شونده دکمه پنل فروشگاه را کلیک و پس از باز شدن لیست فروشگاهها، با کلیک بر روی فروشگاه مورد نظر، وارد پنل مدیریت فروشگاه خود خواهید شد.",
		moreInfo:"content/begin"
	}
]