import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import SectionsPart from './SectionsPart';
import PannelSection from './PannelSection';
import MainSection from './MainSection';
import { sideMenuData } from '../Data.jsx';
import {FiChevronDown } from "react-icons/fi";
import Begin from '../Contents/ContentView';
import { ContentData } from '../ContentData';
import TitleView from '../Contents/TitleView';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import { MySearch } from '../Components/MySearch';



const FullPage = styled.div`
  width:100vw;
  padding-top:10rem;
  display:flex;
  overflow:auto;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position:relative;
background:white;

`

function SearchPage(props){
  const location = useLocation();
  let  from  =props.data?props.data: location.state.from;
  let  searchText  =location.state.searchText;
  console.log(from);
  const columns = [
    {flex:1,field: 'title', headerName: 'sss' }  ];
  return(
  <>
    <FullPage>
    <MySearch
        rows={from}
        columns={columns}
        title={searchText}
        thin={true}
      />
    </FullPage>
  </>
)

}
export default SearchPage;

