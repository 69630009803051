import React, { Component } from "react";
import styled from  '@emotion/styled';

export function AddShopRequest(props) {
  
  return (
<svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_683_21609)">
<path d="M11.0417 35.3335H8.83333C7.6137 35.3335 6.625 36.3222 6.625 37.5418V44.1668C6.625 45.3865 7.6137 46.3752 8.83333 46.3752H11.0417C12.2613 46.3752 13.25 45.3865 13.25 44.1668V37.5418C13.25 36.3222 12.2613 35.3335 11.0417 35.3335Z" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.25 44.1668C13.25 44.7524 13.4827 45.3141 13.8968 45.7283C14.3109 46.1424 14.8726 46.3751 15.4583 46.3751H23.7528C24.2279 46.3751 24.6904 46.2219 25.0715 45.9383C25.4526 45.6546 25.7321 45.2557 25.8684 44.8006L28.5184 38.1756C28.7172 37.5064 28.8121 36.7843 28.3948 36.2234C27.9796 35.6625 27.1978 35.3334 26.5 35.3334H22.0833V29.8855C22.0831 29.3643 21.9152 28.857 21.6045 28.4386C21.2938 28.0202 20.8568 27.7127 20.358 27.5617C19.8592 27.4107 19.325 27.4241 18.8344 27.6C18.3438 27.7759 17.9228 28.1049 17.6335 28.5384L13.25 35.3334V44.1668V44.1668Z" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.9141 6.625V15.4583C30.9141 16.044 31.1467 16.6057 31.5609 17.0199C31.975 17.434 32.5367 17.6667 33.1224 17.6667H41.9557" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.0391 26.7208V11.0417C11.0391 9.87029 11.5044 8.7469 12.3327 7.91861C13.161 7.09033 14.2844 6.625 15.4557 6.625H30.9141L41.9557 17.6667V41.9583C41.9557 43.1297 41.4904 44.2531 40.6621 45.0814C39.8338 45.9097 38.7104 46.375 37.5391 46.375H32.4599" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_683_21609">
<rect width="53" height="53" fill="white"/>
</clipPath>
</defs>
</svg>


  );
}
export function NewContract(props) {
  return (
<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_683_21611)">
<path d="M18.0807 15.5C14.9456 18.1473 12.6996 21.6941 11.6468 25.6601C10.5939 29.6261 10.7852 33.8198 12.1946 37.6736C13.604 41.5273 16.1635 44.8549 19.5266 47.2059C22.8898 49.5568 26.894 50.8177 30.9974 50.8177C35.1008 50.8177 39.105 49.5568 42.4682 47.2059C45.8313 44.8549 48.3908 41.5273 49.8002 37.6736C51.2096 33.8198 51.4008 29.6261 50.348 25.6601C49.2952 21.6941 47.0492 18.1473 43.9141 15.5" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31 10.3335V31.0002" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_683_21611">
<rect width="62" height="62" fill="white"/>
</clipPath>
</defs>
</svg>


  );
}
export function NewMessages(props) {
  return (
<svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_683_21603)">
<path d="M30.9141 6.625V15.4583C30.9141 16.044 31.1467 16.6057 31.5609 17.0199C31.975 17.434 32.5367 17.6667 33.1224 17.6667H41.9557" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.5391 46.375H15.4557C14.2844 46.375 13.161 45.9097 12.3327 45.0814C11.5044 44.2531 11.0391 43.1297 11.0391 41.9583V11.0417C11.0391 9.87029 11.5044 8.7469 12.3327 7.91861C13.161 7.09033 14.2844 6.625 15.4557 6.625H30.9141L41.9557 17.6667V41.9583C41.9557 43.1297 41.4904 44.2531 40.6621 45.0814C39.8338 45.9097 38.7104 46.375 37.5391 46.375Z" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.875 37.5415H33.125" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.875 28.7085H33.125" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_683_21603">
<rect width="53" height="53" fill="white"/>
</clipPath>
</defs>
</svg>

  );
}
export function NewPrimaryGoodRequest(props) {
  return (
<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_683_21619)">
<path d="M8.25 52.25H57.75" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M49.5 16.5H16.5C14.9812 16.5 13.75 17.7312 13.75 19.25V41.25C13.75 42.7688 14.9812 44 16.5 44H49.5C51.0188 44 52.25 42.7688 52.25 41.25V19.25C52.25 17.7312 51.0188 16.5 49.5 16.5Z" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_683_21619">
<rect width="66" height="66" fill="white"/>
</clipPath>
</defs>
</svg>


  );
}
export function NewPropertyRequst(props) {
  return (
<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_683_21615)">
<path d="M11.25 9H20.25L24.75 20.25L19.125 23.625C21.5347 28.5109 25.4891 32.4653 30.375 34.875L33.75 29.25L45 33.75V42.75C45 43.9435 44.5259 45.0881 43.682 45.932C42.8381 46.7759 41.6935 47.25 40.5 47.25C31.7233 46.7166 23.4453 42.9896 17.2279 36.7721C11.0104 30.5547 7.28336 22.2767 6.75 13.5C6.75 12.3065 7.22411 11.1619 8.06802 10.318C8.91193 9.47411 10.0565 9 11.25 9" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.75 15.75C34.9435 15.75 36.0881 16.2241 36.932 17.068C37.7759 17.9119 38.25 19.0565 38.25 20.25" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.75 6.75C37.3304 6.75 40.7642 8.17232 43.2959 10.7041C45.8277 13.2358 47.25 16.6696 47.25 20.25" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_683_21615">
<rect width="54" height="54" fill="white"/>
</clipPath>
</defs>
</svg>



  );
}
export function NewSupplements(props) {
  return (
<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_683_21623)">
<path d="M22.8776 27.9583C28.4925 27.9583 33.0443 23.4066 33.0443 17.7917C33.0443 12.1768 28.4925 7.625 22.8776 7.625C17.2627 7.625 12.7109 12.1768 12.7109 17.7917C12.7109 23.4066 17.2627 27.9583 22.8776 27.9583Z" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.625 53.375V48.2917C7.625 45.5953 8.69613 43.0094 10.6027 41.1027C12.5094 39.1961 15.0953 38.125 17.7917 38.125H27.9583C30.6547 38.125 33.2406 39.1961 35.1473 41.1027C37.0539 43.0094 38.125 45.5953 38.125 48.2917V53.375" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M40.6641 7.95557C42.8509 8.5155 44.7893 9.78735 46.1735 11.5706C47.5577 13.3539 48.309 15.5471 48.309 17.8045C48.309 20.062 47.5577 22.2552 46.1735 24.0384C44.7893 25.8217 42.8509 27.0936 40.6641 27.6535" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M53.375 53.3751V48.2918C53.3621 46.0479 52.6072 43.8713 51.228 42.1013C49.8487 40.3313 47.9227 39.0673 45.75 38.5063" stroke="#0064E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_683_21623">
<rect width="61" height="61" fill="white"/>
</clipPath>
</defs>
</svg>



  );
}