import React,{useEffect, useState} from 'react';
import styled from  '@emotion/styled';
import { CgMenu,CgCloseO } from "react-icons/cg";
import Data from "../Data.json"
import NavbarmenuItem from './NavbarMenuItem';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { HyperBGLogo } from './SideMenuIcons';
import useWindowDimensions from './SizeDetector';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import Button from './Button';
import Search from './Search';
import { sideMenuData } from '../Data.jsx';
import { ContentLink } from '../new/ContentPage';


const StyledNavLink = styled(NavLink)`
background:var(--bg-color-nav);

text-decoration: none;

&:active {
color: none;
}

`;

const MainNav = styled.nav`
width:100%;
height:0;
  background:var(--bg-color-nav);
  font-size:1.4rem;
  font-weight:500;
  transition:all 500ms;
  position:reletive;
  z-index:2;
  &:before {
  content: " test";
  width: 100%;
  position:block;
  height:4rem;
  background:transparent;
  }

.active-menu{
    width: 100%;
    position: fixed;
    top: 0px;
    transition: 0.3s linear;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    z-index: 40;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    background:var(--bg-color-nav);
    height: 4rem;

  }
.hidden{
    height: 4rem;
    width: 100%;
    z-index: 40;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    position: fixed;
    top: -80px;
    transition: 0.3s linear;
    background:var(--bg-color-nav);
}
background:var(--bg-color-nav);

`;



const TogglebleMenu = styled.div`
transition:all 500ms;
width:100%;
display:flex;
flex-direction:column;
border-radius:0 0 10px 10px;
justify-self:center;
`
const LogoContainer = styled.div`

padding:1rem 0 ;
width:100%;
svg{
    width:35%;
   }
  display:flex;
  flex-direction:row-reverse;
  align-items:center;
  justify-content:end;
  font-weight:800;
  font-size:1.6rem;
  color:white;



`

const NavbarMenu = (props) => {
    const isMenu = props.actives
    const { height, width } = useWindowDimensions();
    const location = useLocation()
    const [search,setSearch] = useState(document.getElementById("search-home")?true:false)
    useEffect(()=>{
      setSearch(document.getElementById("search-home")?true:false)
    },[location])

const [openMenu, setOpenMenu] = useState(true);
const toggleMenu = () => {
  if(width<760)
  setOpenMenu(openMenu === false ? true : false);
};
let arr = []
for(let item in Data.menus){
arr.push(false)
}
const [submenuStates,setSubmenuState] = useState(arr);

const submenuFunction = (e)=>{
  console.log(e);
  let arr = []
  if(submenuStates[e]===false){
    for(let item in Data.menus){
      if((item-(-1)-1)===e)
        arr.push(true)
      else arr.push(false);
    }
  }
  else{
    for(let item in Data.menus){
    arr.push(false);
    }
  }
  setSubmenuState(arr);
}


const [show, setShow] = useState(false);
const [lastScrollY, setLastScrollY] = useState(0);

const controlNavbar = () => {
if (typeof window !== 'undefined') { 
  if(window.scrollY<1){
    setShow(false); 
  }
  else if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
    setShow(false); 
  } else { // if scroll up show the navbar
    setShow(true);  
  }

  // remember current page location to use in the next move
  setLastScrollY(window.scrollY); 
}
};

useEffect(() => {
if (typeof window !== 'undefined') {
  window.addEventListener('scroll', controlNavbar);

  // cleanup function
  return () => {
    window.removeEventListener('scroll', controlNavbar);
  };
}
}, [lastScrollY]);
const x = useParams()["*"]
const [activeContent,setActiveContent] = useState(x) 
console.log(activeContent)
   const [activHam,setActiveHam] = useState(false)
   console.log(activHam)
   const linkContent = (name) =>{
    setActiveContent(name)
    window.scrollTo({top: 0, behavior: 'smooth'});
    
  }
  function openInNewTab() {
    window.open('http://37.32.31.80:9149/','_blank').focus();
  }
  
    return (
      <MainNav  className={`active-menu ${show && 'hidden'}`}>

                    <TogglebleMenu style={{direction:width>760?"ltr":"rtl"}} className={`active-menu ${show && 'hidden'}`} actives={openMenu}>
                    <Header>
                    <StyledNavLink  exact="true"  to="/">
                      <LogoContainer desktop={true}>
                      <HyperBGLogo unique="b" isactive={!isMenu}/> {width>760?`  مرکز پشتیبانی | `:null}
                      </LogoContainer>
                    </StyledNavLink>
                    {

                      width>760?
                      search?
                      null
                      :
                      <Search navbar/>
                      :
                      null

                    }
                    {width>760?
                    <div style={{width:width>760?"10%":"30%"}}>
                    <Button onClick={openInNewTab} state="primary" type="1">سایت اصلی</Button>
                    </div>
                    :
                    location.pathname.startsWith('/content')?
                    <Hamburger active={activHam} onClick={()=>setActiveHam(activHam?false:true)}/>
                    :
                    <div style={{width:width>760?"10%":"30%"}}>
                    <Button onClick={openInNewTab} state="primary" type="1">سایت اصلی</Button>
                    </div>
                    }
                    </Header>
                    <Container active={activHam}>
                    {
                      search?
                      null
                      :
                      <Search navbar closeNav={setActiveHam}/>
                     }

                    <SideMenu>
              {sideMenuData.map((item)=><ContentLink
                activeContent={x}
                closeNav={setActiveHam}
                onClick={linkContent}
                title={item.title}
                multiple={item.type==="multiple"}
                submenus={item.content}
                name={"/content/"+item.name}
                somewhereelse
                   />)}

            </SideMenu>
            <div style={{width:width>760?"10%":"30%"}}>
                    <Button onClick={openInNewTab} state="primary" type="1">سایت اصلی</Button>
                    </div>
                    </Container>
                    </TogglebleMenu>                     
                    
                    </MainNav>     
          
    )
}


const Container = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
  background:var(--bg-color-nav);
  overflow:auto;
  width:100vw;
  height:120vh;
  position:absolute;
  transition:all 300ms;
  right:${props=>props.active?"0":"-100vw"};
  z-index:3;
  background:var(--bg-color-nav);

`
const SideMenu = styled.div`
text-align: right;
margin:3rem 0;
font-size:1.5rem;
direction:rtl;
background:#0050c979;
width:100%;
height:40%;
padding:0 3rem;
box-sizing:border-box;
overflow:auto;
z-index:1000;
`
export default NavbarMenu

const Header = styled.div`
  width:100%;
  padding:0 2rem;
  box-sizing:border-box;
  display:flex;
  flex-direction:row-reverse;
  justify-content:space-between;
  align-items:center;
  background:var(--bg-color-nav);
  border-radius:0 0 10px 10px;
  z-index:1000;


`

const Hamburger = ({onClick,active})=>{
  const [name,setName] = useState("menu btn")
  return(
    <XOS onClick={onClick} size="6">
  <div className={active?"menu open btn":"menu btn"} onClick={name==="menu btn"?()=>setName("menu open btn"):()=>setName("menu btn")} data-menu="10">
  <div class="icon"></div>
  </div>
  </XOS>
  )
  }
  const XOS = styled.div`
  --original-width:${props => (props.size+"rem")};
  --original-height:${props => (props.size/7.5+"rem")};
  --third:${props => (props.size/3+"rem")};
  --third-min:${props => (-props.size/3+"rem")};
  --tenth:${props => (props.size/10+"rem")};
  --tenth-min:${props => (-props.size/10+"rem")};
  transform:scale(0.5);
  position:relative;


width:fit-content;

.btn {
  width:${props => (props.size+"rem")};
  height: ${props => (props.size+"rem")};;
  transition-duration: 0.5s;
}
.btn .icon {
  transition-duration: 0.5s;
  position: absolute;
  height: ${props => (props.size/7.5+"rem")};
  width: ${props => (props.size+"rem")};
  top: 30px;
  background-color: white;
  border-radius:10px;
}
.btn .icon:before {
  transition-duration: 0.5s;
  position: absolute;
  height: ${props => (props.size/7.5+"rem")};
  width: ${props => (props.size+"rem")};
  background-color: white;
  content: "";
  top: -20px;
  border-radius:10px;
}
.btn .icon:after {
  transition-duration: 0.5s;
  position: absolute;
  height: ${props => (props.size/7.5+"rem")};
  width: ${props => (props.size+"rem")};
  background-color: white;
  content: "";
  top: 20px;
  border-radius:10px;
}
.btn.open .icon {
  transition-duration: 0.5s;
  transform: rotateZ(-180deg);
}
.btn.open .icon:before {
  transform: rotateZ(-45deg) scaleX(0.75) translate(var(--third-min), var(--tenth-min));
}
.btn.open .icon:after {
  transform: rotateZ(45deg) scaleX(0.75) translate(var(--third-min), var(--tenth));
}
.btn:hover {
  cursor: pointer;
}

  `
