import React, { Children, useState } from 'react';
import styled from  '@emotion/styled';
const Output = styled.button`
--color-b-1:#24B600;
--color-b-2:#F56819;
--color-b-3:#2236C0;
--color-b-warning:#D4B011;
--b-accent-color:${props =>
                (props.state==="warning" ?
                "var(--color-b-warning)":
                  props.types===1 ?
                  "var(--color-b-1)":
                props.types===2 ?
                  "var(--color-b-2)":
                  "var(--color-b-3)"
                )};
--b-bg-color:${props =>
               (props.state==="warning" ?
                "var(--bg-color-inside)":
                props.state==="primary" ?
                  "var(--b-accent-color)":
                props.state==="secondary" ?
                  "var(--bg-color-inside)":
                  "transparanet"
                )};
--b-text-color:${props =>
               (props.state==="warning" ?
                "var(--color-b-warning)":
                props.state==="primary" ?
                "white":
                props.types===1 ?
                  "var(--color-b-1)":
                props.types===2 ?
                  "var(--color-b-2)":
                  "var(--color-b-3)"
                )};


transition:all 200ms;
box-sizing:border-box;
margin:0;
background:var(--b-bg-color);
border:solid 1.5px var(--b-accent-color);
color:var(--b-text-color);
display:flex;
align-items:center;
justify-content:space-around;
font-weight:700;
font-size:1.2rem;
border-radius:1rem;
${props=>props.fullHeight?
`
height:100%;
`:
`
height:100px;
max-height:35px;
`
}

width:100%;
min-height:30px;
cursor: pointer;
a{
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: var(--b-text-color);
    align-content: center;
    align-items: center;
    justify-content: center;
}
&:focus{
  outline:none
}
&:active{
  border:${props=>props.state==="primary"?"solid 1.5px var(--b-accent-color)":"solid 1.5px (--bg-color-inside)"};
  background-color:${props=>props.state==="primary"?"var(--bg-color-inside)":"var(--b-accent-color)"};
  color:${props=>props.state==="primary"?"var(--b-accent-color)":"white"};
  a{
    color:${props=>props.state==="primary"?"var(--b-accent-color)":"white"};
  }
  svg{
  fill:var(--b-accent-color);
}

}
svg{
  fill:var(--b-text-color);
}
`
function Button(props) {
  const state=props.state;
  const type=props.type;
  return (
    <Output fullHeight={props.fullHeight} type={props.t} form={props.form} value={props.value} onClick={props.onClick} state={state} types={type}>
      {props.children}
    </Output>

  );
}
export default Button;