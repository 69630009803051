import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import SectionsPart from './SectionsPart';
import PannelSection from './PannelSection';
import MainSection from './MainSection';
import FaqPart from './FaqPart';
import Fotter from './Fotter';


const FullPage = styled.div`
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position:relative;
background:white;

`


function HomePage(props){
return(
  <>
  <MainSection/>
  <PannelSection/>
  <SectionsPart/>
  <FaqPart/>
  <Fotter/>
  </>
)
}
export default HomePage;
