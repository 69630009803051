import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Link, withRouter } from 'react-router-dom'
import { cd } from '../ContentData';
import { ContentLink } from '../Components/Link';
import Search from '../Components/Search';





const FullPage = styled.div`
  width:100vw;
  height:100vh;
  background:#003078;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:start;
  position:relative;

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		z-index: 0;
		position: absolute;
		top: 0; 
		left: 0;
		bottom: 0;
		right: 0;
		background: radial-gradient(50vw 60vw at 50% 70%,
              rgba(255, 255, 255, 0.28) 0%,
                rgba(255, 255, 255, 0) 60%), 
                radial-gradient(65% 15% at 50% 85%,
              rgba(255, 255, 255, 0.23) 0%,
                rgba(255, 255, 255, 0) 100%); 
	}
  .answer {
    z-index:100000;
    direction:rtl;
  background:white;
  padding: 0 20px 20px;
  line-height: 3rem;
  font-size:1.2rem;
  margin:1rem 0 ;
  width:100%;
  padding:0 2rem;
  box-sizing:border-box;
  border-radius:10px;
  position:absolute;
}
`


function MainSection(props){

  
const FAQ = [
  {question:"آیا سفارش های جدید قابل رهگیری است؟"},
  {question:"چطور کاربری جدید ایجاد کنم؟"},
  {question:"پیگیری سریع وضعیت سفارش"},
  {question:"گزینه کیف پول چه استفاده ای دارد؟"},
  {question:"شرایط قرارداد چیست؟ "},
  {question:"پیگیری سریع وضعیت سفارش"},
  {question:"الحاقیه جدید را کجا جستجو کنم؟"},
  {question:"می خواهم یک محصول را حذف کنم"},
  {question:"پیگیری سریع وضعیت سفارش"},
  {question:"گزینه کیف پول چه استفاده ای دارد؟"},
  {question:"شرایط قرارداد چیست؟ "},
  {question:"پیگیری سریع وضعیت سفارش"},
  {question:"الحاقیه جدید را کجا جستجو کنم؟"}
]
function getMultipleRandom(arr, num) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
}

const arr = ['b', 'c', 'a', 'd'];
console.log(getMultipleRandom(arr, 2)); // 👉️ ['a', 'c'];
console.log(getMultipleRandom(arr, 3)); // 👉️ ['c', 'b', 'c']
let FAQ_container = document.getElementById("FAQ")
const sc = ()=>{
  let FAQ_container = document.getElementById("FAQ")
console.log(FAQ_container)
  document.getElementById("FAQ").scrollIntoView(
    {
      top:"2rem",
      left: 100,
      behavior: 'smooth'
    }
  );
}

return(
  <FullPage>
      <Banner>هــرآنــچــه میــخواهــیــد بـــدانــیــد</Banner>

      <Search/>
      <QuestionsBox>
      {getMultipleRandom(FAQ, 6).map(item=><Question>{item.question}</Question>)}
      </QuestionsBox>
      <ScrollToFAQ onClick={sc}>
      <section onClick={sc} id="section05" class="demo">
  <div onClick={sc}>
    سوالات متداول
    <span onClick={sc}>
    </span>
    </div>
</section>
      </ScrollToFAQ>

  </FullPage>
)
}
export default MainSection;

const Banner = styled.div`
font-weight: 700;
box-sizing:border-box;
@media (max-width:760px){
  font-size: 2.1rem;
  line-height: 3rem;
}
font-size: 2.8rem;
line-height: 3.6rem;
text-align: center;
color: #FDFEFF;
text-shadow: 0px 0px .35rem #FDFEFF;
padding:15rem 3rem 3rem 3rem;
`
const Question = styled.div`
border:1px solid white;
color:white;
box-sizing:border-box;
padding:1rem;
margin: 1rem 0.5rem 0 0;
font-weight: 700;
@media (max-width:760px){
  font-size: 1rem;
}
font-size: 1.2rem;
line-height: 1.4rem;

border-radius:40px;
opacity:0.4;
transition:300ms;

-webkit-user-select: none;     
-moz-user-select: none;
-ms-user-select: none;
user-select: none;


&:hover{
  opacity:1;
  background:white;
  color:#003078;

}
`
const QuestionsBox = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  @media (max-width:760px){
  width:100%;
}
  width:55%;
  box-sizing: border-box;
  padding: 2rem;
`

const ScrollToFAQ = styled.div`
margin:2rem 0 ;
width:100%;
section {
  position: relative;
  width: 100%;
  height: 100%;
}
section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 80%;

}
span,div{
  cursor:pointer;
  z-index:1000;
}


#section05 div {
  color:white;
  text-align:center;
  display:flex;
  justify-content:center;
  animation: text 1.5s infinite;


}
#section05 div span {
  position: absolute;
  top: 5px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-10px, 10px);
    opacity: 0;
  }
  
}
@keyframes text {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}




`