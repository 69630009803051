import React from 'react';
import styled from  '@emotion/styled';
import { Link } from 'react-router-dom';


const NumberBox = styled.span`
width:25px;
height:25px;
border:1px solid var(--black-and-white);
border-radius:5px;
display:flex;
align-items:center;
justify-content:center;
`
export function NumberBar(props){
  return(
    <NumberBox>
      {props.number}
    </NumberBox>
  )
}

export function EditIcon(props){
  return(
    <svg style={{cursor:"pointer"}} onClick={props.onClick} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3301 1.87793H8.45208C3.75695 1.87793 1.87891 3.75598 1.87891 8.4511V14.0852C1.87891 18.7804 3.75695 20.6584 8.45208 20.6584H14.0862C18.7813 20.6584 20.6594 18.7804 20.6594 14.0852V12.2072" stroke="var(--black-and-white)" strokeOpacity="0.9" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.062 2.83581L7.66244 10.2353C7.38074 10.517 7.09903 11.071 7.04269 11.4748L6.63891 14.3013C6.48866 15.3248 7.21171 16.0385 8.23525 15.8976L11.0617 15.4939C11.4561 15.4375 12.0101 15.1558 12.3012 14.8741L19.7007 7.47459C20.9778 6.19751 21.5788 4.71385 19.7007 2.83581C17.8227 0.957756 16.339 1.55873 15.062 2.83581Z" stroke="var(--black-and-white)" strokeOpacity="0.9" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.002 3.89697C14.6311 6.14124 16.3871 7.89722 18.6407 8.53575" stroke="var(--black-and-white)" strokeOpacity="0.9" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export function DeleteIcon(props){
  return(
    <svg style={{cursor:"pointer"}} onClick={props.onClick} width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.1224 8.98096C18.1224 18.3377 19.4692 22.5671 10.4103 22.5671C1.35027 22.5671 2.72489 18.3377 2.72489 8.98096" stroke="var(--black-and-white)" strokeOpacity="0.9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.8446 5.39404H1" stroke="var(--black-and-white)" strokeOpacity="0.9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.4191 5.39389C14.4191 5.39389 15.0359 1 10.4219 1C5.80915 1 6.42589 5.39389 6.42589 5.39389" stroke="var(--black-and-white)" strokeOpacity="0.9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export const StyledTableLink = styled(Link)`
text-decoration:none;
color:var(--black-and-white);
&:visited{
  color:var(--black-and-white);
}
`
export const TableLink = styled.button`
text-decoration:none;
border:none;
background:none;
color:var(--black-and-white);
cursor: pointer;
`
const PictureContainer = styled.div`
width:100%;
${props=>props.page?"min-height:200px;":""}
display: flex;
align-content: center;
justify-content: center;
align-items: center;
img {
width:100%
}
`
export const TablePic = (props)=>{
  return(
    <PictureContainer page={props.page}>
      <img src={props.src} alt={props.alt}></img>
    </PictureContainer>
  )
}