const begin  = {
    type:
    "single",
    name:
    `begin`,
    title:
    `راهنمای  مربوط به پنل فروشندگان پلتفرم لوکسین لوکس`,
    text_1:
    `پلتفرم لوکسین لوکس به هر فروشنده منحصرا پنل فروشگاهی اختصاص می دهد که فروشندگان می توانند با این پنل تمام کارهای خود از قبیل افزودن محصولات، لیست سفارشات مشتریان، پیامها، نظرات و پرسش و پاسخ خریداران در مورد محصولات ، تراکنشهای مالی و گزارشهای متنوع مشاهده و مدیریت نمایند.`,
    img_1:
    `/images/Begin (1).jpg`,
    text_2:
    `برای ورود به پنل فروشگاه از طریق صفحه کاربری خود، از منوی باز شونده دکمه پنل فروشگاه را کلیک و پس از باز شدن لیست فروشگاهها، با کلیک بر روی فروشگاه مورد نظر، وارد پنل مدیریت فروشگاه خود خواهید شد.`,
    img_2:
    `/images/Begin (2).jpg`,
    img_3:
    `/images/Begin (3).jpg`,
    text_4:
    `پس از ورود، پنل مدیریت فروشگاه برای شما نشان داده خواهد شد.`,
    img_4:
    `/images/Begin (4).jpg`,
    text_5:
    `در این صفحه تمامی اطلاعات مربوط به مدیریت فروشگاه قابل مشاهده می باشد.
    گزارش مهمترین خریداران، دیدگاههای اخیر کاربران، بازدیدهای اخیر و کیف پول نیز در این صفحه نمایش داده شده که با زدن دکمه مشاهده در زیر هر بخش، گزارش آن قابل دسترسی خواهد بود. 
    همچنین در بالای صفحه، اطلاع رسانی های  مهم سیستم از جمله تعداد پیامهای جدید، تعداد سفارشات جدید، موجودیهای کالا های به حداقل رسیده ، الحاقیه های جدید و تعداد مرجوعی های جدید قابل مشاهده می باشدکه با کلیک بر روی هر کدام از موارد ذکر شده به صفحه همان موارد ارجاع داده می شوید.
    `,
    img_5:
    `/images/Begin (5).jpg`,
    }
const dashboard  = {
        type:
        "single",
        name:
        `dashboard`,
        title:
        "پیشخوان فروشگاه",
        text_1:
        "در این صفحه، دسترسی سریع به برخی امکانات مهم در اختیار شما قرار گرفته است. شما می توانید محصول جدید اضافه کنید، پیام های جدید ارسال شده را ببینید و الحاقیه ها و قراردادهای خود را مشاهده نمایید. ",
        text_2:
        "همچنین اطلاعات مالی فروشگاه، لیست خریداران، بازدید های اخیر و دیدگاههای کاربران نیز با یک نگاه، قابل مشاهده می باشد که با کلیک بر روی دکمه مشاهده به صفحه همان موضوع منتقل خواهید شد." ,
        img_2:
        `/images/dashboard.jpg`
    }
const newProduct  = {
        type:
        "single",
        name:
        `newProduct`,
        title:
        "افزودن محصول جدید",
        text_1:
        "این صفحه جهت اضافه کردن محصول جدید در فروشگاه و پلتفرم لوکسین لوکس مورد استفاده قرار می گیرد.",
        text_2:
        "با زدن دکمه افزودن محصول جدید، وارد صفحه زیر خواهید شد.",
        img_1:
        `/images/newProduct (1).jpg`,
        img_2:
        `/images/newProduct (2).jpg`,
        text_3:
        "در این صفحه با 4 گزینه روبرو می شوید. برای اضافه کردن محصول ابتدا گروه کالای خود را انتخاب کرده، سپس زیرگروه کالا و پس از آن برند کالا و از قسمت کالای اصلی، کالای مورد نظر خود را انتخاب کرده، سپس دکمه ثبت اولیه را کلیک نمایید.",
        img_3:
        `/images/newProduct (3).jpg`,
        img_4:
        `/images/newProduct (4).jpg`,
        text_4:
        "با زدن ثبت اولیه، پنجره بعدی که شامل دو قسمت، اطلاعات و مشخصات کالای اصلی می باشد، نمایش داده می شود. در قسمت بالایی صفحه اطلاعات شناسنامه ای مربوط به کالای اصلی به شما نشان داده خواهد شد.",
        img_5:
        `/images/newProduct (5).jpg`,
        img_6:
        `/images/newProduct (6).jpg`,
        text_5:
        "در قسمت پایین این صفحه  اطلاعات تکمیلی مربوط به کالا اعم از نوع ویژگی، کشور، بازه زمانی ارسال، موجودی، قیمت و سایر موارد مربوط به کالای فروشگاهی مورد نظر خود را را وارد کرده و دکمه افزودن اطلاعات را بزنید.",
        img_7:
        `/images/newProduct (7).jpg`,
        text_6:
        "در پنجره بعدی عکسهای محصول را اضافه کرده و دکمه ذخیره تصاویر کالا را بزنید. توجه داشته باشید که برای هر محصول تا 4 تصویر قادر به بار گذاری خواهید بود.",
        text_7:
        " پس از تکمیل فرآیند اضافه کردن محصول فروشگاهی سیستم بصورت اتوماتیک الحاقیه کالایی برای فروشگاه شما صادر خواهد کرد که پس از بررسی و تایید کارشناسان فروش ، جهت فروش در پلتفرم هاپر بی جی، قابل نمایش و انتخاب توسط خریداران خواهد بود.",
        img_8:
        `/images/newProduct (8).jpg`,
        text_8:
        "در فرآیند اضافه کردن محصول در هر قسمتی که  نیاز به اضافه کردن اطلاعات وارد نشده استاندارد، مانند گروههای کالایی، زیر گروهها، برند ها، در لیست های مورد نظر بود ،با زدن  دکمه + در کنار همان لیست ، صفحه ای باز می شود که با پر کردن آن میتوانید اطلاعات درخواستی خود را به کارشناسان لوکسین لوکس ارسال نمایید تا در اسرع وقت به سیستم اضافه نمایند. با این کار درخواست شما برای گروه پشتیبانی ارسال شده و در صورت تایید اطلاعات شما  به لیست اضافه خواهد شد.",
        img_9:
        `/images/newProduct (9).jpg`,
        img_10:
        `/images/newProduct (10).jpg`,
        text_9:
        "در مورد زیرگروه کالا و برند نیز فرآیند به همین صورت می باشد. ",
        text_10:
        "در فرآیند اضافه کردن کالای فروشگاهی ، اگر کالای اصلی مورد نظر در لیست انتخاب کالای اصلی وجود نداشته باشد، با زدن دکمه + کنار لیست محصولات اصلی ، وارد صفحه زیر خواهید شد:",
        img_11:
        `/images/newProduct (11).jpg`,
        text_11:
        'در این قسمت، ابتدا شما بایستی اطلاعات کالای اصلی را به سیستم اضافه کرده و در گامهای بعدی اطلاعات مربوط به کالای فروشگاهی را وارد نمایید. ',
        text_12:
        'برای این منظور ابتدا گروه کالا، زیر گروه کالا، برند کالا  و  واحد کالای مورد نظر را انتخاب نمایید.',
        img_12:
        `/images/newProduct (12).jpg`,
        text_13:
        "سپس دکمه ثبت اولیه را کلیک نمایید.",
        img_13:
        `/images/newProduct (13).jpg`,
        text_14:
        "سپس وارد صفحه ورود اطلاعات محصول اصلی خواهید شد. در این صفحه موارد مشخص شده  مانند نام کالا، شرح کالا، و موارد مشخص شده را تکمیل نمایید .",
        img_14:
        `/images/newProduct (14).jpg`,
        text_15:
        'سپس می توانید موارد اختیاری را نیز پر کرده، عکس محصول را در پایین صفحه آپلود نموده و سپس دکمه افزودن اطلاعات را کلیک نمایید.',
        img_15:
        `/images/newProduct (15).jpg`,
        img_16:
        `/images/newProduct (16).jpg`,
        img_17:
        `/images/newProduct (17).jpg`,
        img_18:
        `/images/newProduct (18).jpg`,
        text_16:
        'بعد از آن وارد صفحه زیر خواهید شد. در این صفحه همانطور که قبلا توضیح داده شد، مشخصات کالای اصلی قابل مشاهده است و شما می توانید ویژگیهای کالای فروشگاهی خود را به آن اضافه کرده و دکمه افزودن اطلاعات را کلیک نمایید و در قسمت بعد، عکس کالا را بارگذاری کرده و دکمه ذخیره تصویر کالا را کلیک نمایید.',
        img_19:
        `/images/newProduct (19).jpg`,
        
        img_20:
        `/images/newProduct (20).jpg`,
        img_21:
        `/images/newProduct (21).jpg`,
        img_22:
        `/images/newProduct (22).jpg`,
        text_17:
        'پس از تکمیل فرآیند اضافه کردن محصول فروشگاهی شما یک محصول اصلی و یک محصول فروشگاهی به سیستم اضافه نموده اید که  سیستم بصورت اتوماتیک الحاقیه کالایی برای فروشگاه شما صادر خواهد کرد که پس از بررسی و تایید کارشناسان فروش ، جهت فروش در پلتفرم هاپر بی جی، قابل نمایش و انتخاب توسط خریداران خواهد بود.',
       
    }
const messagesPage = {
    type:
    "multiple",
    name:
    `messagesPage`,
    title:
    "صفحه پیام ها",
    text_1:
    "این صفحه جهت مدیریت پیامها و اعلانات طراحی و در اختیار فروشندگان  قرار داده شده است .", 
    img_1:
    `/images/messagesPage (1).jpg`
}
const newMessages = {
    type:
    "single",
    name:
    `newMessages`,
    title:
    "پیام جدید",
    text_1:
    "در این قسمت با زدن دکمه پیام های جدید، می توانید برحسب موضوع مدنظر خود، پیام جدید ایجاد نموده و به کارشناسان لوکسین لوکس ارسال نمایید .",
    img_1:
    `/images/messagesPage (2).jpg`
}
const messages = {
    type:
    "single",
    name:
    `messages`,
    title:
    "پیام ها",
    text_1:
    "در این صفحه لیستی از تمامی پیامهای ارسالی و  دریافتی خود را مشاهده خواهید کرد که میتوان با انتخاب تاریخ مورد نظر (زدن دکمه های از تاریخ – تا تاریخ) و انتخاب موضوع پیام و سپس زدن دکمه جستجو نتایج را  مشاهده نمود. ",
    img_1:
    `/images/messagesPage (3).jpg`,
    text_2:
    "با کلیک بر روی مشاهده پیام در لیست پیامها ، صفحه چت لیستی باز خواهد شد که تمامی پیامهای مربوط به این چت لیست قابل مشاهده خواهد بود و در صورت بسته نبودن چت لیست توسط کارشناسان، میتوانید پیام خود را در این چت لیست ارسال نمایید.  همچنین پیام های ارسال شده را مشاهده نمایید. ",    
    img_2:
    `/images/messagesPage (4).jpg`
}
const notifications = {
    type:
    "single",
    name:
    `notifications`,
    title:
    "اعلانات",
    text_1:
    "پیام های اطلاع رسانی مانند تایید الحاقیه های قیمت ، خرید مشتریان از فروشگاه شما و ...  که از طرف پلتفرم لوکسین لوکس بصورت اتوماتیک تولید میشود، در این قسمت برای شما قابل مشاهده خواهد بود . در این صفحه نیز همانند صفحه پیامها، میتوان با انتخاب تاریخ مورد نظر (زدن دکمه های از تاریخ – تا تاریخ) و انتخاب موضوع پیام و سپس کلیک بر روی دکمه جستجو نتایج را  مشاهده نمود. ",
    img_1:
    `/images/messagesPage (5).jpg`
}
const archive = {
    type:
    "single",
    name:
    `archive`,
    title:
    "بایگانی",
    text_1:
    `
    این قسمت برای بایگانی اعلانات سیستم مورد استفاده قرار می گیرد.
    در قسمت اعلانات و بعد از مشاهده پیامها، گزینه ای برای بایگانی اعلانات وجود دارد که با کلیک بر روی آن، اعلان مورد نظر به قسمت بایگانی پیامها منتقل می گردد.
      
    `,
    img_1:
    `/images/messagesPage (6).jpg`
}
const supplement = {
    type:
    "multiple",
    name:
    `supplement`,
    title:
    "الحاقیه ها",
    text_1:
    "در پلتفرم لوکسین لوکس الحاقیه ها جزء مهمترین بخشهای پنل بعد از قرارداد ها به شمار می آیند، چرا که هر کاری که در پنل مدیریت مشتریان انجام می پذیرد بایستی در قالب الحاقیه ها انجام شوند. الحاقیه ها به عنوان اسناد و مدارک فروشنده ها محسوب می گردد. ",
    img_1:
    `/images/Supplments (1).jpg`,
    text_2:
    'دو نوع کلی از الحاقیه ها در پلتفرم لوکسین لوکس وجود دارد:'
}
const goodSupplement = {
    type:
    "single",
    name:
    `goodSupplement`,
    title:
    "الحاقیه های کالا",
    text_1:"این نوع الحاقیه به صورت اتوماتیک از طریق سیستم و در دو جای مختلف تولید می گردد. اولین مکان هنگام اضافه کردن محصول فروشگاهی جدید می باشد، که پس از ثبت نهایی محصول توسط فروشنده، این الحاقیه تولید و به کارشناسان فروش جهت بررسی و تایید ارسال میگردد و کالای مورد نظر تنها در صورتی در سایت اصلی و صفحه مخصوص فروشنده قابل نمایش و انتخاب توسط مشتریان خواهد بود که این الحاقیه توسط کارشناسان فروش تایید شده باشد.",
    text_2:"مکان بعدی هنگام ویرایش سریع محصول در صفحه لیست محصولات می باشد که فروشنده پس از کلیک بر روی دکمه ویرایش و پر کردن اطلاعات و ثبت ویرایش الحاقیه کالای خود را تولید می نماید. در اینجا نیز الحاقیه به صورت اتوماتیک تولید و به کارشناسان فروش جهت بررسی و تایید ارسال میگردد و کالای مورد نظر تنها در صورتی بروز رسانی می گردد که این الحاقیه توسط کارشناسان فروش تایید شده باشد.",
    img_1:
    `/images/Supplments (2).jpg`,
    img_2:
    `/images/Supplments (3).jpg`,
}
const otherSupplement = {
    type:
    "single",
    name:
    `otherSupplement`,
    title:
    "سایر الحاقیه ها",
    text_1:
    "منظور از سایر الحاقیه ها در پلتفرم لوکسین لوکس ، آن دسته از الحاقیه ها هستند که وابسته به قرارداد ها می باشند مانند (تمدید تاریخ قرارداد، اضافه کردن نکاتی به قرارداد و ...)",
    text_2:
    "برای دسترسی به منوی سایر الحاقیه ها از منوی قرارداد، گزینه مشاهده جزییات از قرارداد مورد نظر را کلیک کرده و در صفحه  قرارداد روی تب سایر الحاقیه ها کلیک کرده و دکمه اضافه کردن الحاقیه کلیک نمایید. ابتدا اطلاعات کلی الحاقیه مانند تاریخ و شرح الحاقیه را  وارد کرده و سپس دکمه افزودن جزییات را کلیک کرده و اطلاعات مربوط به جزییات الحاقیه خود را تکمیل نمایید. توجه داشته باشید که بنا به نیاز میتوانید بیش از یک جزییات برای هر الحاقیه ثبت نمایید.",
    text_3:
    "بعد از وارد کردن جزییات الحاقیه ، الحاقیه جهت بررسی و کنترل به کارشناسان مربوطه ارجاع داده می شود.",
    text_4:
    "در پایین صفحه سایر الحاقیه ها نیز لیست تمام سایر الحاقیه های مربوط به قرارداد انتخاب شده قابل مشاهده می باشند.",    
    img_1:
    `/images/Supplments (4).jpg`
}
const contracts = {
    type:
    "multiple",
    name:
    `contracts`,
    title:
    "قرارداد ها",
    text_1:
    "در این بخش با زدن دکمه قراردادها، جزئیات قرارداد فروشگاه شما قابل مشاهده می باشد. ",
    text_2:
    "شما می توانید تاریخ مدنظر خود را انتخاب نموده و با زدن دکمه مشاهده جزئیات، تمامی اطلاعات مربوط به قرارداد را مشاهده نمایید. ",
    text_3:
    "تب های موجود در صفحه جزییات قرارداد شامل اطلاعات قرارداد، الحاقیه ها و سایر الحاقیه ها می باشند. توجه داشته باشید که تب های الحاقیه ها مربوط به الحاقیه کالاهای اضافه شده به سیستم بوده و تب سایر الحاقیه ها مربوط به انواع مختلفی از الحاقیه ها مانند تمدید قرارداد، تغییر قیمت قرارداد و .... می باشد که هر کدام قابلیت فیلتر شدن در لیست هایی موجود را دارند.",
    img_1:
    `/images/contracts (1).jpg`,
    img_2:
    `/images/contracts (2).jpg`,
    img_3:
    `/images/contracts (3).jpg`,
}
const orders = {
    type:
    "single",
    name:
    `orders`,
    title:
    "سفارشات",
    text_1:
    "با زدن دکمه سفارشات، وارد صفحه سفارشات می شوید. در این صفحه لیست تمامی سفارشات ثبت شده در سیستم توسط خریداران برای شما نمایش داده می شود. در این صفحه تب های زیر قابل مشاهده است:",
}
const allOrders = {
    type:
    "single",
    name:
    `allOrders`,
    title:
    "همه سفارشات",
    text_1:
    "در این صفحه لیست تمامی سفارشات خریداران نشان داده می شود  ",
    text_2:
    "شما با انتخاب تاریخ شروع و پایان مورد نظر، وضعیت سفارش، نام خریدار و شماره فاکتور و سپس زدن دکمه جستجو کلیه اطلاعات مربوط به سفارشات خود را مشاهده می کنید. ",
    img_1:
    `/images/orders (1).jpg`,

}
const prepairingOrders = {
    type:
    "single",
    name:
    `prepairingOrders`,
    title:
    "سفارشات در حال آماده سازی",
    text_1:
    "این صفحه لیست سفارشات جدید را نشان می دهد که با کلیک بر روی دکمه آبی رنگ میتوان اطلاعات مربوط به هر سفارش را مشاهده نمود. بعد از مشاهده جزییات هر سفارش با کلیک بر روی دکمه آماده سازی مرسوله ، به صفحه آماده سازی سفارش ارجاع داده می شوید. پس از کنترل این صفحه و انتخاب کارشناس تحویل دکمه ارسال کد مرسوله را کلیک نمایید. کلیک کردن دکمه ارسال کد مرسوله  به منزله ارسال محصول مشتری به صورت فیزیکی می باشد.",
    img_1:
    `/images/orders (2).jpg`,
    img_2:
    `/images/orders (3).jpg`,
    img_3:
    `/images/orders (4).jpg`,
}
const deliverdOrders = {
    type:
    "single",
    name:
    `deliverdOrders`,
    title:
    "سفارشات تحویل شده",
    text_1:
    "این صفحه لیست سفارشات تحویل شده به خریداران را نشان می دهد که با کلیک بر روی دکمه سبز رنگ میتوان اطلاعات مربوط به هر سفارش را مشاهده نمود. بعد از مشاهده جزییات هر سفارش با کلیک بر روی دکمه فاکتور فروش ، به صفحه نمایش فاکتور رسمی مربوط به این سفارش ارجاع داده می شوید. که درصورت درخواست خریداران، میتوانید این فاکتور را پرینت و به خریداران ارسال نمایید. ",
    img_1:
    `/images/orders (5).jpg`,
    img_2:
    `/images/orders (6).jpg`,
    img_3:
    `/images/orders (7).jpg`,
}
const returned = {
    type:
    "single",
    name:
    `returned`,
    title:
    "مرجوعی",
    text_1:
    "این صفحه لیست محصولات مرجوع شده از طرف خریداران را نشان می دهد که با کلیک بر روی دکمه قرمز رنگ میتوان اطلاعات مربوط به هر مرجوعی را مشاهده نمود. بعد از کنترل علت مرجوعی و مشاهده فایلهای مربوطه میتوانید مرجوعی را تایید نمایید.",
    img_1:
    `/images/orders (8).jpg`,
    img_2:
    `/images/orders (9).jpg`
}
const products = {
    type:
    "multiple",
    name:
    `products`,
    title:
    "محصولات",
    text_1:
    "با زدن دکمه محصولات، وارد صفحه زیر می شوید.",
    text_2:
    "در قسمت محصولات شما می توانید لیست کلیه محصولات فروشگاه به همراه ویژگیهای آنها را طبق فیلتربندی مورد نظر خود و سپس زدن دکمه جستجو، مشاهده کنید. ",
    text_3:
    "همچنین در این قسمت امکان ویرایش مشخصات کالای وارد شده نیز وجود دارد. ",
    text_4:
    "اگر دکمه کنار محصول قرمز باشد یعنی محصول شما هنوز توسط کارشناسان لوکسین لوکس تایید نشده است و باید منتظر تایید آن باشید واگر دکمه کنار محصول آبی باشد، یعنی محصول توسط کارشناسان لوکسین لوکس تایید شده است.",
    img_1:
    `/images/products (1).jpg`
}
const editProduct = {
    type:
    "single",
    name:
    `editProduct`,
    title:
    "ویرایش محصول",
    text_1:
    "برای ویرایش اطلاعات محصول، روی دکمه دایره ای انتهای هر محصول کلیک کرده و دکمه ویرایش را کلیک نمایید. بعد از کلیک گزینه های قیمت ، تعداد کالای قابل اضافه شدن به انبار، کمیسیون، بازه ارسال به مشتری، حداقل موجودی انبار را ویرایش کرده و دکمه ثبت را کلیک نمایید. پس از ثبت ویرایش محصول الحاقیه کالایی برای این محصول تولید و به کارشناسان فروش جهت بررسی و تایید ارسال می گردد که پس از تایید توسط کارشناسان فروش اطلاعات کالای شما در وب سایت به روز رسانی می گردد.",
    img_4:
    `/images/products (5).jpg`
}
const viewProductInfo = {
    type:
    "single",
    name:
    `viewProductInfo`,
    title:
   "مشاهده اطلاعات محصول",
    text_1:
    "جهت مشاهده  اطلاعات محصول، پس از کلیک بر روی دکمه دایره ای مربوط به محصول مورد نظر، گزینه مشاهده اطلاعات محصول را کلیک نمایید. در صفحه اطلاعات محصول کلیه اطلاعات محصول به صورت دسته بندی شده نمایش داده میشود که در صورت لزوم میتوانید در پایین صفحه و در قسمت تصاویر کالا، عکسهای مربوط به کالای خود را ویرایش نمایید. ",
    img_1:
    `/images/products (2).jpg`,
    img_2:
    `/images/products (3).jpg`,
    img_3:
    `/images/products (4).jpg`,
    img_5:
    `/images/products (6).jpg`,
}
const commentsAndQuestions = {
    type:
    "multiple",
    name:
    `commentsAndQuestions`,
    title:
    "نظرات و سوال و جواب",
    text_1:
    "در پلتفرم لوکسین لوکس به منظور ارتباط فروشندگان با مشتریان دو بخش نظرات و سوال و جواب کاربران تعبیه شده است که توضیحات بخش های آن به شرح زیر می باشد: "
}
const comments = {
    type:
    "single",
    name:
    `comments`,
    title:
   "نظرات",
    text_1:
    "با کلیک روی دکمه نظرات، وارد صفحه نظرات می شوید. در این بخش نظرات کاربران سایت درمورد محصولات فروشگاه شما قابل رویت می باشد. در این صفحه  شما می توانید با انتخاب تاریخ مورد نظر و سپس کلیک بر روی دکمه جستجو ، نظرات کاربران را مشاهده نمایید.",
    img_1:
    `/images/commentsAndQuestions (1).jpg`,

}
const qa = {
    type:
    "single",
    name:
    `qa`,
    title:
   "سوال و جواب",
    text_1:
    "با کلیک روی دکمه پرسش و پاسخ ، وارد صفحه پرسش و پاسخ می شوید. در این بخش سوالات و پاسخهای کاربران سایت درمورد محصولات فروشگاه شما قابل رویت می باشد. در این صفحه  شما می توانید با انتخاب تاریخ مورد نظر و سپس کلیک بر روی دکمه جستجو ، سوالات کاربران را مشاهده نمایید. همچنین با کلیک بر روی دکمه پاسخ میتوانید، به سوالهای کاربران پاسخ مناسب ارسال نمایید. ",
    img_1:
    `/images/commentsAndQuestions (2).jpg`,

}
const financialReports = {
    type:
    "single",
    name:
    `financialReports`,
    title:
    "گزارشات مالی",
    text_1:
    "با کلیک روی دکمه گزارشات مالی، وارد صفحه گزارشات مالی می شوید. در این قسمت گزارش مالی فروشگاه بر حسب تاریخ مورد نظر و یا به صورت روزانه، هفتگی، .... به صورت نمودار قابل مشاهده می باشد. ",
    text_2:
    "همچنین در این بخش گزارش بازدیدهای اخیر، پرفروش ترین محصولات و کم فروش ترین محصولات و همچنین گزارش مشتریان جدید خود را نیز می توانید مشاهده نمایید.",
    img_1:
    `/images/financialReports (1).jpg`,
    text_3:
    "همچنین گزارش فروش بر حسب تاریخ، زیرگروه، برند و یا کدکالای مورد نظر نیز پس از کلیک روی دکمه جستجو قابل دسترسی می باشد.",
    img_2:
    `/images/financialReports (2).jpg`,
}
const transactions = {
    type:
    "multiple",
    name:
    `transactions`,
    title:
    "تراکنش ها (کیف پول)",
    text_1:
    "با کلیک روی دکمه تراکنش ها، وارد صفحه زیر می شوید.",
    img_1:
    `/images/transactions (1).jpg`,
    text_2:
    "در این صفحه تاریخچه تراکنش های مربوط به فروشگاه قابل مشاهده و گزارش گیری می باشد. ",
    text_3:
    "شما می توانید با انتخاب تاریخ مورد نظر، وضعیت تراکنش و انواع تراکنش و سپس کلیک روی دکمه جستجو گزارش مورد نظر خود را مشاهده نمایید. "
}
const transactionsState = {
    type:
    "single",
    name:
    `transactionsState`,
    title:
    "وضعیت تراکنش",
    text_1:
    "این گزینه تمام وضعیتهای تراکنشهایی که در فروشگاه شما اتفاق افتاده را نشان می دهد که شامل موارد زیر می باشد: ",
    bulletText_1:[    
    "قابل برداشت: شامل تراکنشهایی می باشند که فرآیند  های تسویه، ارسال و تحویل محصولات با موفقیت به پایان رسیده است و شما قادر به درخواست تسویه مالی اینگونه تراکنشها می باشید.",
    "غیر قابل برداشت : شامل تراکنشهایی می باشند که هنوز به مرحله پایانی خود نرسیده است . برای مثال اگر خریداران محصولاتی را تسویه کرده ولی هنوز از طرف فروشگاه ارسال نگردیده است و یا هنوز تحویل قطعی نگردیده باشد ، تراکنشهای مربوطه به عنوان غیر قابل برداشت در سیستم نمایش داده خواهد شد.",
    "تسویه شده: به تراکنشهایی گفته می شود که مبلغ آنها به حساب بانکی معرفی شده از طرف فروشندگان واریز و تسویه کامل خرید بر مبنای تسهیم انجام گرفته باشد.",
    "لغو شده: به تراکنشهایی که خرید آنها لغو گردیده است ، گفته می شود.",
    ],
    img_1:
    `/images/transactions (2).jpg`,
    text_2:
    "در این صفحه تاریخچه تراکنش های مربوط به فروشگاه قابل مشاهده و گزارش گیری می باشد. ",
    text_3:
    "شما می توانید با انتخاب تاریخ مورد نظر، وضعیت تراکنش و انواع تراکنش و سپس کلیک روی دکمه جستجو گزارش مورد نظر خود را مشاهده نمایید. "
}
const transactionsVariety = {
    type:
    "single",
    name:
    `transactionsVariety`,
    title:
    "انواع تراکنشها",
    text_1:
    "این گزینه انواع تراکنشهایی که در فروشگاه شما اتفاق افتاده را نشان می دهد که شامل موارد زیر می باشد: ",
    bulletText_1:[    
    "خرید: این نوع تراکنشها از طریق فرآیند خرید توسط خریداران ، در سیستم ایجاد می گردد.",
    "بازگشت وجه مرجوعی: این نوع تراکنشها از طریق فرآیند مرجوعی توسط خریداران ، در سیستم ایجاد می گردد.",
    "سود بازاریابی: این نوع تراکنشها از طریق فرآیند بازاریابی ، در سیستم ایجاد می گردد.",
    "تسویه مالی: این نوع تراکنشها از طریق فرآیند تسویه توسط فروشندگان ، در سیستم ایجاد می گردد و نشان دهنده این موضوع است که تراکنش مورد نظر در ارتباط با تسویه فروشندگان با پلتفرم لوکسین لوکس صورت گرفته است.",
    "افزایش اعتبار: این نوع تراکنشها از طریق فرآیند افزایش اعتبار ، در سیستم ایجاد می گردد.",
    "نقدینگی امتیاز : این نوع تراکنشها از طریق فرآیند امتیازات و درخواست نقدینگی امتیاز از سوی خریداران و یا فروشندگان ، در سیستم ایجاد می گردد."
    ],
    img_1:
    `/images/transactions (3).jpg`
}
const buyers = {
    type:
    "single",
    name:
    `buyers`,
    title:
    "خریداران",
    text_1:
    "با کلیک روی دکمه خریداران، وارد صفحه زیر می شوید.",
    text_2:
    "در این بخش اطلاعات مربوط به بهترین خریداران فروشگاه شما طبق تاریخ انتخابی قابل مشاهده می باشد.  این اطلاعات شامل نام  ، مبلغ خریداری شده، تاریخ اولین خرید، تاریخ آخرین خرید و آخرین محصول  خریداری شده توسط آنها می باشد که با زدن دکمه جستجو می توانید آنها را مشاهده کنید.",
    img_1:
    `/images/buyers.jpg`
}
const depositRequest = {
    type:
    "single",
    name:
    `depositRequest`,
    title:
    "درخواست واریز به حساب",
    text_1:
    "با کلیک بر روی این دکمه، فرم درخواست واریز به حساب (تسویه) برای شما باز شده که با وارد کردن مبلغ مورد نظر به تومان، درخواستی به واحد مالی جهت فرآیند تسویه ارسال می گردد که پس از بررسی و درصورت تایید مبلغ تایید شده به حساب معرفی شده شما واریز می گردد و تراکنشی نیزدر قسمت تراکنشها به همان مبلغ تایید شده ایجاد میگردد.",

    img_1:
    `/images/transactions (4).jpg`
}
const settings = {
    type:
    "single",
    name:
    `settings`,
    title:
    "تنظیمات",
    text_1:
    "این صفحه برای تنظیمات مربوط به ظاهر صفحه اختصاصی فروشگاه شما می باشد که شامل موارد زیر است:"
}
const help = {
    type:
    "single",
    name:
    `help`,
    title:
    "راهنما",
    text_1:
    "این بخش شما را هدایت به این سایت می کند تا بتوانید با فرایند های موجود در پنل فروشندگان آشنا کند"    
}
const start = {
    type:
    "single",
    name:
    `start`,
    title:
    "آغاز به کار",
    text_1:
    "در ابتدا پس از وارد شدن به سایت لوکسین لوکس، دکمه ورود را کلیک نمایید. ",
    img_1:
    `/images/start (1).jpg`,
    text_2:
    "پس از این مرحله وارد صفحه لاگین خواهید شد. ",
    img_2:
    `/images/start (2).jpg`,
    text_3:
    "در صفحه لاگین پس از وارد کردن شماره موبایل، دکمه ارسال کد تایید را کلیک نموده. منظر بمانید تا کد تایید برای شما پیامک شود. ",
    text_4:
    "سپس با نوشتن کد تایید ارسال شده،  وارد پنل کاربری خود خواهید شد. ",
    img_3:
    `/images/start (3).jpg`,
    text_5:
    "در این صفحه روی دکمه درخواست فروشندگی کلیک کنید. ",
    text_6:
    "در صفحه درخواست فروشندگی ، کلیه اطلاعات فروشگاه را به طور کامل و صحیح وارد نموده و گزینه ارسال درخواست فروشندگی را کلیک نمایید.",
    img_4:
    `/images/start (4).jpg`,
    text_7:
    "بدین ترتیب درخواستی برای کارشناسان لوکسین لوکس ارسال می شود که پس از بررسی توسط کارشناسان لوکسین لوکس در صورت تایید اولیه ، کارشناسان قرارداد لوکسین لوکس ، جهت طی فرآیند عقد قراداد با شما تماس خواهند گرفت."
}
const about = {
    type:
    "single",
    name:
    `about`,
    title:
    "درباره محصول",
    text_1:
    "ظهور فناوری اطلاعات، انقلابی نو را برای بخش‌های مختلف جامعه بشری، از جمله صنایع و کسب‌و‌کارها رقم زده است. پیدایش اینترنت موجب پیدایش تجارت‌های جدیدی شده که پیش از آن وجود نداشتند. ",
    text_2:
    "در این میان کشورهاي در حال توسعه از اهمیت خاصی برخوردارند و ارزش این کشورها براي سازندگان برندهاي جهانی به خوبی شناخته شده است .تعداد زیاد مشتریان بالقوه، رشد اقتصادي بالا و افزایش سریع توقعات مردم کشورهاي در حال توسعه منجر شده است که این کشورها بازار هدف خوبی براي کشورهاي توسعه یافته محسوب شوند.",
    text_3:
    "در دنیاي امروز معیارهاي پذیرفته شدة مصرف در جامعه یا طبقهاي که شخص به آن تعلق دارد، استاندارد زندگی او را تعیین میکند؛ این استاندارد با میزان مصرف شخص از معیارهاي عمومی شکل می گیرد، زیرا فرد بر اساس عادت، شیوة زندگی خود را بر مبناي این معیارها شکل میدهد.",
    text_4:
    "یکی از دلایلی که فرآیند خرید سنتی را لذت بخش می‌کند گشت و گذار در بازار، تماشای ویترین‌ها، مقایسه محصولات مشابه، قیمت‌ها و تنوع کالاها می‌باشد. با توجه به تغییر در سبک زندگی، کمبود زمان و همچنین مشغله افراد، خریدهای آنلاین گزینه مناسب‌تری برای مشتریان به نظر می‌رسد. ",
    text_5:
    "لوکسین لوکس یک پلتفرم خرید و فروش محصولات لوکس در بستری کاملا امن است. امتیاز رقابتی ما در بازاری با خاص ترین و با کیفیت ترین محصولات ، مبتنی بر بهترین و ترند ترین سلیقه ها با دسترسی سریع و آسان است. فروشندگان و صاحبان کسب و کار در لوکسین لوکس با در اختیار داشتن صفحه مخصوص خود به شیوه ای کاملا پویا و منحصر به برند مرتبط خودشان، محصولات خود را معرفی و می فروشند. و همچنین از امکانات به روز و حرفه ای مدیریت جامع در صفحه اصلی برخوردار خوهند بود آنچه در شیوه راهبری لوکسین لوکس شاخصه است:",
    bulletText_1:[
        "حفظ امنیت مالی خرید است به صورتی که به محض ورود فروشگاه به سایت، سیستم کیف پول الکترونیک در بانک پاسارگاد برای فروشگاه افتتاح میگردد و مبالغ واریز شده هر خرید به طور امانی در نزد بانک پاسارگاد حفظ و پس از حصول نهایی تاییدات ،به حساب فروشنده واریز می گردد.",
        "ایجاد بستری مناسب برای عرضه محصولات،  نمایش و تبلیغات بنر ها و پروموشن های محصولات ، ایجاد پنل اس ام اس و امکان گزارش گیری های کامل و دقیق از وضعیت سفارشات، محصولات، کاربران و اطلاعات خریداران خواهد بود."
    ],
    text_6:
    "لوکسین لوکس نخستین پلتفرم فروش آنلاین کالای لوکس است که با تکیه بر اصول مشتری مداری، کلیه نیازهای فروشندگان و خریداران کالاهای لوکس را برآورده کرده و به دلیل کاربری آسان و روان، برای استفاده افراد مناسب می باشد.",
    text_7:
    "لوکسین لوکس با قرار دادن مواردی مانند نام و پنل تخصصی فروشگاه، تراکنش های مالی، دسترسی آسان به کیف پول و گزارش مشتریان برای فروشگاهها و جنس ، قیمت ، تنوع در سایز و رنگ، امکان مقایسه با کالاهای مشابه، زمان تحویل کالا و امکان کنسلی و عودت کالا برای خریداران، لذت خرید و فروش را فراهم کرده است. ",
    text_8:
    "در پلتفرم لوکسین لوکس 6 گروه کالای لوکس با برندهای مختلف از فروشگاههای لوکس عرضه می گردد:",
    bulletText_2:[
        "ساعت و زیور آلات",
        "لوازم آشپزخانه",
        "لوازم تزئینی و دکوری",
        "فرش و قالیچه",
        "پوشاک و منسوجات",
        "آرایشی و بهداشتی"
    ]
}
export const cd = [
    {data:about,name:"about"},
    {data:start,name:"start"},
    {data:begin,name:"begin"},
    {data:dashboard,name:"dashboard"},
    {data:newProduct,name:"newProduct"},
    {data:messagesPage,name:"messagesPage"},
    {data:newMessages,name:"newMessages"},
    {data:messages,name:"messages"},
    {data:notifications,name:"notifications"},
    {data:archive,name:"archive"},
    {data:supplement,name:"supplement"},
    {data:goodSupplement,name:"goodSupplement"},
    {data:otherSupplement,name:"otherSupplement"},
    {data:contracts,name:"contracts"},
    {data:"",name:"goodSupplement"},
    {data:"",name:"otherSupplement"},
    {data:orders,name:"orders"},
    {data:allOrders,name:"allOrders"},
    {data:prepairingOrders,name:"prepairingOrders"},
    {data:deliverdOrders,name:"deliverdOrders"},
    {data:returned,name:"returned"},
    {data:products,name:"products"},
    {data:editProduct,name:"editProduct"},
    {data:viewProductInfo,name:"viewProductInfo"},
    {data:commentsAndQuestions,name:"commentsAndQuestions"},
    {data:comments,name:"comments"},
    {data:qa,name:"qa"},
    {data:financialReports,name:"financialReports"},
    {data:transactions,name:"transactions"},
    {data:transactionsState,name:"transactionsState"},
    {data:transactionsVariety,name:"transactionsVariety"},
    {data:depositRequest,name:"depositRequest"},
    {data:buyers,name:"buyers"},
    {data:settings,name:"settings"},
    {data:help,name:"help"}
]

export const ContentData =  (name)=>{
for(let item of cd){
    if(item.name===name)
        return item.data
}
}