import React, { useState, useRef, useEffect } from 'react';
import styled from  '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { cd } from '../ContentData';
import { ContentLink } from '../Components/Link';
import { Link } from 'react-router-dom';


const SearchContainer = styled.div`
height: 65px;
height: ${props=>props.navbar?"unset":"65px"};

background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
border-radius: 40px;
display:flex;
flex-direction:row-reverse;
justify-content:center;
align-items:center;
.input{
      display:flex;
      width: 90%;
      height: 100%;
      border-radius: 40px;
      flex-direction:row-reverse;
      justify-content:center;
      align-items:center;
      background-color:var(--bg-color-inside);
      padding:0 2rem;
}
.MuiInput-root:before{
  display: none!important;
}
.MuiInput-root:after{
  display: none!important;
}

.MuiInputBase-adornedEnd{
  flex-direction:row-reverse;
  input{
    text-align:right;
  }
}

`
const Input = styled.input`
  box-sizing:border-box;

  background-color:var(--bg-color-inside);
  outline:none;
  border:none;
  transition: 500ms;
  :-webkit-input-placeholder::before {
  color:#666;
  content:"Line 1\A Line 2\A Line 3\A";
}
  &:hover::placeholder {
  opacity: 0;
}
  &:focus{
    outline:none;
  border:none;
  }
  border-radius:10px;
  direction:rtl;
  padding:10px;
  width:100%;
  color:var(--black-and-white);

`
function QuickSearchToolbar(props) {
  return (
    <SearchCon id={props.navbar?"":"search-home"} ref={props.refs}>
    <SearchContainer navbar={props.navbar}>
    <div className="input">
    <SearchIcon fontSize="large" />
            <Input
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        onKeyPress={e=>{
          if(e.charCode===13){
          document.getElementById("search").click();
          props.closeNave(false);
        }
      }}
      />
             
          
          <IconButton
            title="Clear"
            aria-headerName="Clear"
            size="small"
            style={{ visibility: props.value ? 'visible' : 'hidden' }}
            onClick={props.clearSearch}
          >
          <ClearIcon fontSize="small" />
          </IconButton>
    </div>
    </SearchContainer>
    {
      props.navbar?
      null
      :
      <div class="answer">
      {
        props.searchResult.map((item)=>{
          return<ContentLink name={item.name} title={item.title}></ContentLink>
        })
      }
      </div>
    }

    </SearchCon>

  );
}
const SearchCon = styled.div`
position:relative;
min-width:30rem;
width:50%;
`
function escapeRegExp(value){
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
const requestSearch = (searchValue,setSearchResult,setShowRows,rows) => {
  const searchRegex = new RegExp(escapeRegExp(searchValue),"i");
  const filteredRows_1 = rows.filter((row) => {
    return Object.keys(row).some((field) => {
      if(field==="title")
      return row[field]?searchRegex.test(row[field].toString()):"";
    });
  });
  const filteredRows_2 = rows.filter((row) => {
    return Object.keys(row).some((field) => {
      if(field!=="title"){
        let corect = true
        for(let key of filteredRows_1 ){
          if(key===row)
          corect = corect&&false
        }
        if(corect)
        return row[field]?searchRegex.test(row[field].toString()):"";
      }
    });
  });
  const filteredRows = [...filteredRows_1,...filteredRows_2]
  setSearchResult(filteredRows)
};



function Search(props){
  let arr = []
for(let item of cd){
  arr.push(item.data)
}
const [searchResult,setSearchResult] = useState([])
const [searchText,setSearchText] = useState()

  const handleKeyPress = (e,value)=> {
    setSearchText(e.target.value)
    requestSearch(e.target.value,setSearchResult,setShowRows,arr)

  }

const [rows,setFRows] = useState(arr)
const [showRows,setShowRows] = useState([])
function useOutsideAlerter(ref) {
    useEffect(() => {

      function handleClickOutside(event) {
          console.log("x")

        if (ref.current && !ref.current.contains(event.target)) {
            console.log("x")
            setSearchResult([])
        }
      }
      function scrooll(){
        setSearchResult([])
      }
      document.addEventListener('scroll', scrooll)
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
return(
    <>
      <Link style={{display:"none"}}  to={`search/${searchText}`} state={{ from: searchResult,searchText:searchText }} id="search" ></Link>
      <QuickSearchToolbar closeNave={props.closeNav} navbar={props.navbar} refs={wrapperRef}
      placeholder="جستجو کنید ..." 
      value={searchText}
      searchResult={searchResult}
      onChange={handleKeyPress}
      clearSearch={() => {setSearchResult([]);setSearchText()}}/>
      </>

)
}
export default Search;

