import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import ContentView from './ContentView';
import { ContentData } from '../ContentData';
import { NavLink } from 'react-router-dom';



function TitleView(props){
  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[props])
      console.log(props)
   const data = props.data
   console.log(data)
   const h = "props.h"
  return(<>
<ContentView data={ContentData(props.name)}/>
<Container>
    {data.map((item)=>
    <Menu to={item.name}>
    <ItemContainer onClick={()=>props.link(item.name)}>
      <h2 style={{display:"flex",alignItems:"center",flexDirection:"row-reverse",justifyContent:"space-between"}}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.5 18.75C3.09 18.75 2.75 18.41 2.75 18V7C2.75 2.59 4.09 1.25 8.5 1.25H15.5C19.91 1.25 21.25 2.59 21.25 7V17C21.25 17.16 21.25 17.31 21.24 17.47C21.21 17.88 20.84 18.2 20.44 18.17C20.03 18.14 19.71 17.78 19.74 17.37C19.75 17.25 19.75 17.12 19.75 17V7C19.75 3.43 19.08 2.75 15.5 2.75H8.5C4.92 2.75 4.25 3.43 4.25 7V18C4.25 18.41 3.91 18.75 3.5 18.75Z" fill="#292D32"/>
<path d="M17 22.75H7C4.66 22.75 2.75 20.84 2.75 18.5V17.85C2.75 15.86 4.37 14.25 6.35 14.25H20.5C20.91 14.25 21.25 14.59 21.25 15V18.5C21.25 20.84 19.34 22.75 17 22.75ZM6.35 15.75C5.19 15.75 4.25 16.69 4.25 17.85V18.5C4.25 20.02 5.48 21.25 7 21.25H17C18.52 21.25 19.75 20.02 19.75 18.5V15.75H6.35Z" fill="#292D32"/>
<path d="M16 7.75H8C7.59 7.75 7.25 7.41 7.25 7C7.25 6.59 7.59 6.25 8 6.25H16C16.41 6.25 16.75 6.59 16.75 7C16.75 7.41 16.41 7.75 16 7.75Z" fill="#292D32"/>
<path d="M13 11.25H8C7.59 11.25 7.25 10.91 7.25 10.5C7.25 10.09 7.59 9.75 8 9.75H13C13.41 9.75 13.75 10.09 13.75 10.5C13.75 10.91 13.41 11.25 13 11.25Z" fill="#292D32"/>
</svg>


        {item.title}</h2>
      <P>{item.text_1}</P>
    </ItemContainer>
     </Menu>
    )}
</Container>
  </>
    )
}
export default TitleView;

const Container = styled.div`
  padding-top:5rem;
  box-sizing:border-box;
  width:100%;
  display:grid;
  grid-template-columns:1fr 1fr;
  width: 100%;
  display: grid;
  grid-template-columns:49% 49%;
  row-gap: 2rem;
  column-gap: 2%;
`
const ItemContainer = styled.div`
  width:100%;
  transition:all 500ms;
  border-radius:10px;
  padding:2rem;
  box-sizing:border-box;
  width:100%;
  height:13rem;
  background: #F8F8FA;
  box-shadow: 0px 0px 3px 3px #4d49491a;

  :hover{
    box-shadow: 0px 1px 10px 3px rgba(77,73,73,0.82);
  -webkit-box-shadow: 0px 1px 10px 3px rgba(77,73,73,0.82);
  -moz-box-shadow: 0px 1px 10px 3px rgba(77,73,73,0.82);
  }
  cursor:pointer;
`
const P =  styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
`
const Menu = styled(NavLink)`
  text-decoration:none;
  :visited,:link{
    color:#414141;
  }

`
