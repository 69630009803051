import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import { cd } from '../ContentData';
import { NavLink } from 'react-router-dom';
import { HiArrowSmRight,HiArrowSmLeft } from "react-icons/hi";




function ContentView(props){
  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[props])
  let next,back
  const first = props.data
  for(let key of cd){
    if(key.name===first.name){
      next =(cd[cd.indexOf(key)+1]?cd[cd.indexOf(key)+1].name:null)
      back = (cd[cd.indexOf(key)-1]?cd[cd.indexOf(key)-1].name:null)
    }
  }
  console.log(next,back)
    const view = []
    for (const property in first) {
      if(property.startsWith("title"))
        view.push(<h1>{first[property]}</h1>)
      else if(property.startsWith("text"))
      view.push(<P>{first[property]}</P>)
      else if(property.startsWith("bulletText")){
        let temp = 
        <UL>
          {first[property].map((item=>
            <li>{item}</li>
            ))}
        </UL>
        view.push(temp)
      }
      else if(property.startsWith("img"))
        view.push(
          <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
        <IMG src={first[property]} alt=''/>
          </div>
        )  
    }

  return(
  <>
  {view.map((item)=>item)}
    <div style={{width:"100%",display:"flex",flexDirection:"row-reverse",justifyContent:"space-between"}}>
    {next?
    <Menu to={"/content/"+next}>
      بعدی 
    <HiArrowSmLeft/>
    </Menu>
  :
  <DeMenu style={{color:"#8b8b8b9e"}}>
  بعدی 
<HiArrowSmLeft/>
</DeMenu>}
{back?
    <Menu to={"/content/"+back}>
    <HiArrowSmRight/>
      قبلی 
    </Menu>
  :
  <DeMenu style={{color:"#8b8b8b9e"}}>
<HiArrowSmRight/>
  قبلی 
</DeMenu>}
    </div>
  </>
)
}
export default ContentView;
const IMG = styled.img`
@media (max-width:760px){
  width:100%;
}
@media (min-width:760px){
  width:70%;
}
  
  height:auto;
  padding:1rem;
  box-sizing:border-box;
  border-radius:20px;
  box-shadow: 0px 1px 10px 1px #4d494953;
  margin:2rem 0;
  min-width:50vw;
  min-height:30vh;
`
const P = styled.p`
    text-align: justify;
  text-justify: inter-word;
  line-height:3rem;
  font-weight:450;
`
const UL = styled.ul`
  line-height:5rem;
  padding-right:10rem;
  font-weight:600;
`
const Menu = styled(NavLink)`
  text-decoration:none;
  :visited,:link{
    color:${props => (props.active? "#2b3cf6" : "#414141")};

  }
  

  display: flex;
  flex-direction: row;
  align-items:center;
  column-gap:.5rem;
  padding:1rem;
  box-sizing:border-box;
  cursor:pointer;
  transition:all 300ms;
  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`
const DeMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items:center;
  column-gap:.5rem;
  padding:1rem;
  box-sizing:border-box;

`