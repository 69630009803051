import styled from '@emotion/styled'
import { useState, useMemo } from 'react'
import { NumberBar } from './TableIcons'
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Pagination } from '@mui/material';
import { useEffect } from 'react';
import { Loading } from './Loading';
import { NavLink } from 'react-router-dom';
import Search from './Search';

const CPagination = styled(Pagination)`
  .MuiPagination-ul{
  .MuiButtonBase-root{
    font-size:1.2rem;
    transition:all 500ms;
    font-family: iranyekan, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color:var(--black-and-white);
  }
  .Mui-selected{
    transition:all 500ms;
    color:white;
    background: linear-gradient(90.08deg, #108FF4 0.05%, #213BC2 99.96%);
  }
}
.MuiPaginationItem-ellipsis{
  color: var(--black-and-white);
  font-family: iranyekan,-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif!important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  font-weight: 700;
  font-size: 1.5rem;
}
  .MuiTablePagination-actions{
    transform: scalex(-1);
  }
  direction:rtl;
  font-family: iranyekan,-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif!important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  font-weight: 700;
  font-size: 1.8rem;
  align-items: center;
  min-height: 52px;
  display: flex;
  color: var(--black-and-white);

  svg{
    transform:rotate(180deg);
  }
`
const Tr = styled.tr`
display:grid;
grid-template-columns:${props=>props.flex};
justify-content: space-around;
width: 100%;
height:100%;
`
const TrBody = styled(Tr)`
transition: all 500ms;
background:var(--bg-color-inside);
color:var(--black-and-white);
font-size:1.2rem;
min-height:${props=>props.thin?"6rem":"11.5rem"};
border-radius: 5px;
border-bottom: 0.1px solid rgba(85, 85, 85, 0.5);
border-right: 0.1px solid rgba(85, 85, 85, 0.5);
border-left: 0.1px solid rgba(85, 85, 85, 0.5);
box-sizing:border-box;
justify-content: space-around;
`
const TableContainer = styled.div`
width:90%;
direction:rtl;
display:flex;
flex-direction:column;
align-items:center;
`
  

const Sorting = styled.span`
    cursor:pointer;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    opacity:${props => props.selected?"1":"0.5"};
    :hover{
      opacity:1;
    }
`
const SearchContainer = styled.div`
width:100%;
display:flex;
flex-direction:row-reverse;
justify-content:center;
align-items:center;
.input{
  display:flex;
flex-direction:row-reverse;
justify-content:center;
align-items:center;
background-color:var(--bg-color-inside);
padding:0 2rem;
border-radius:10px;
}
.MuiInput-root:before{
  display: none!important;
}
.MuiInput-root:after{
  display: none!important;
}
.MuiInputBase-adornedEnd{
  flex-direction:row-reverse;
  input{
    text-align:right;
  }
}

`
const Input = styled.input`
  box-sizing:border-box;
  background-color:var(--bg-color-inside);
  outline:none;
  border:none;
  &:focus{
    outline:none;
  border:none;
  }
  border-radius:10px;
  direction:rtl;
  padding:10px;
  width:100%;
  max-width:250px;
  color:var(--black-and-white);

`
export function isEmpty(obj = {}) {
  return Object.keys(obj).length === 0
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

export function isNumber(value) {
  return typeof value == 'number' && !isNaN(value)
}

export function isBoolean(value) {
  return value === true || value === false
}

export function isNil(value) {
  return typeof value === 'undefined' || value === null
}

export function isDateString(value) {
  if (!isString(value)) return false

  return value.match(/^\d{2}-\d{2}-\d{4}$/)
}

export function convertDateString(value) {
  return value.substr(6, 4) + value.substr(3, 2) + value.substr(0, 2)
}

export function toLower(value) {
  if (isString(value)) {
    return value.toLowerCase()
  }
  return value
}

export function convertType(value) {
  if (isNumber(value)) {
    return value.toString()
  }

  if (isDateString(value)) {
    return convertDateString(value)
  }

  if (isBoolean(value)) {
    return value ? '1' : '-1'
  }

  return value
}

function QuickSearchToolbar(props) {
  return (
    <SearchContainer>
    <div className="input">
    <SearchIcon fontSize="large" />
            <Input
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
      />
             
          
          <IconButton
            title="Clear"
            aria-headerName="Clear"
            size="small"
            style={{ visibility: props.value ? 'visible' : 'hidden' }}
            onClick={props.clearSearch}
          >
          <ClearIcon fontSize="small" />
          </IconButton>
    </div>

    </SearchContainer>

  );
}
function escapeRegExp(value){
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
const requestSearch = (searchValue,setSearchText,setShowRows,rows) => {
  setSearchText(searchValue);
  const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
  const filteredRows = rows.filter((row) => {
    return Object.keys(row).some((field) => {
      return row[field]?searchRegex.test(row[field].toString()):"";
    });
  });
  setShowRows(filteredRows)
};
function paginateRows(sortedRows, activePage, rowsPerPage) {
  return [...sortedRows].slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage)
}
const CustomPagination = ({title, activePage, count, rowsPerPage, totalPages, setActivePage,header }) => {
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1

  return (
    <div style={{display:"flex",justifyContent:"space-between",width:"90%",direction:"rtl"}}>
      <div style={{display:"flex",alignItems:"center",fontWeight:"700",color:"var(--black-and-white)",fontSize:"1.8rem"}}>
      {header?
      header
    :
    `
    ${count} نتیجه برای جستجوی "${title}" یافت شد
    `}
      </div>

      <CPagination
      color="primary"
      count={totalPages}
      page={activePage}
      onChange={(event, value) => setActivePage(value)}
    />
    </div>
  )
}
 function sortRows(rows, sort) {
  return rows.sort((a, b) => {
    let { order, orderBy } = sort

    if (isNil(a[orderBy])) return 1
    if (isNil(b[orderBy])) return -1

    let aLocale = convertType(a[orderBy])
    let bLocale = convertType(b[orderBy])

    if (order === 'asc') {
      return aLocale.localeCompare(bLocale, 'en', { numeric: isNumber(b[orderBy]) })
    } else {
      return bLocale.localeCompare(aLocale, 'en', { numeric: isNumber(a[orderBy]) })
    }
  })
}
export const MySearch = ({ columns, rows, title, searchable=false,loading=false,header,thin=false}) => {
  columns = [{ field: 'id',renderCell:value=> (<NumberBar number={value.id}/>) }, ...columns]
  let [activePage, setActivePage] = useState(1)
  let [filters, setFilters] = useState({})
  let [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
  let rowsPerPage = 5
  let [fRows,setFRows] = useState(rows)
  let filteredRows = useMemo(()=>fRows[0]?fRows:rows,[rows, fRows])
  let sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  let calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)
  let count = filteredRows.length
  let totalPages = Math.ceil(count / rowsPerPage)
  let handleSort = (field) => {
    setActivePage(1)    
    setSort((prevSort) => {
      if(prevSort.order === 'desc' && prevSort.orderBy === field)
        return { order: 'asc', orderBy: 'id' }
      else 
      return {order: prevSort.order === 'asc' && prevSort.orderBy === field ? 'desc' : 'asc',orderBy: field,}
    }
    )
  }
  let flex = []
  for(let item of columns){
    flex.push(item.flex?item.flex+"fr":"1fr")
  }
  flex = flex.join(' ')
  console.log(flex)


  const [searchText, setSearchText] = useState('');
  if(loading)
  return <Loading/>
  else
  return (
    <>
        <Search/>
      {count > 0 ? (
        <CustomPagination
          title={title}
          activePage={activePage}
          count={count}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          setActivePage={setActivePage}
          header={header}
        />
      ) : (
        null
      )}

      <Container>
        {
        count===0?
          <div> 
                         اطلاعاتی جهت نمایش وجود ندارد
          </div>
        :
        null
        }

        <TableContainer>
          {calculatedRows.map((row) => {
            return (
              <Menu key={rows.indexOf(row)} to={`/content/${row.name}`}>
              <ItemContainer onClick={()=>console.log()}>
                <h2 style={{display:"flex",alignItems:"center",flexDirection:"row-reverse",justifyContent:"space-between"}}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.5 18.75C3.09 18.75 2.75 18.41 2.75 18V7C2.75 2.59 4.09 1.25 8.5 1.25H15.5C19.91 1.25 21.25 2.59 21.25 7V17C21.25 17.16 21.25 17.31 21.24 17.47C21.21 17.88 20.84 18.2 20.44 18.17C20.03 18.14 19.71 17.78 19.74 17.37C19.75 17.25 19.75 17.12 19.75 17V7C19.75 3.43 19.08 2.75 15.5 2.75H8.5C4.92 2.75 4.25 3.43 4.25 7V18C4.25 18.41 3.91 18.75 3.5 18.75Z" fill="#292D32"/>
          <path d="M17 22.75H7C4.66 22.75 2.75 20.84 2.75 18.5V17.85C2.75 15.86 4.37 14.25 6.35 14.25H20.5C20.91 14.25 21.25 14.59 21.25 15V18.5C21.25 20.84 19.34 22.75 17 22.75ZM6.35 15.75C5.19 15.75 4.25 16.69 4.25 17.85V18.5C4.25 20.02 5.48 21.25 7 21.25H17C18.52 21.25 19.75 20.02 19.75 18.5V15.75H6.35Z" fill="#292D32"/>
          <path d="M16 7.75H8C7.59 7.75 7.25 7.41 7.25 7C7.25 6.59 7.59 6.25 8 6.25H16C16.41 6.25 16.75 6.59 16.75 7C16.75 7.41 16.41 7.75 16 7.75Z" fill="#292D32"/>
          <path d="M13 11.25H8C7.59 11.25 7.25 10.91 7.25 10.5C7.25 10.09 7.59 9.75 8 9.75H13C13.41 9.75 13.75 10.09 13.75 10.5C13.75 10.91 13.41 11.25 13 11.25Z" fill="#292D32"/>
          </svg>
          
          
                  {row.title}</h2>
                <P>{row.text_1}</P>
              </ItemContainer>
               </Menu>

            )
          })}
        </TableContainer>
      </Container>
    </>
  )
}
const ItemContainer = styled.div`
  transition:all 500ms;
  border-radius:10px;
  box-sizing:border-box;
  width:100%;
  padding:3rem;
  margin:2rem;
  background: #F8F8FA;
  box-shadow: 0px 0px 3px 3px #4d49491a;

  :hover{
    box-shadow: 0px 1px 10px 3px rgba(77,73,73,0.82);
  -webkit-box-shadow: 0px 1px 10px 3px rgba(77,73,73,0.82);
  -moz-box-shadow: 0px 1px 10px 3px rgba(77,73,73,0.82);
  }
  cursor:pointer;
`
const P =  styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
`
const Menu = styled(NavLink)`
  text-decoration:none;
  :visited,:link{
    color:#414141;
  }
  width:100%;

`
const Container = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
`