import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Data from "../Data.json"

import {
    AddShopRequest,
    NewContract,
    NewMessages,
    NewPrimaryGoodRequest,
    NewPropertyRequst,
    NewSupplements
} from '../Components/DashboardIcons';


import { StyledTableLink } from '../Components/TableIcons';
const Svg = [
    {svg:AddShopRequest,title:"راهنمای محصول",subtitle:"فروشگاه ها و مدیریت کاربری",link:"/content/begin"},
    {svg:NewContract,title:"آغاز به کار",subtitle:"ضوابط و قراردادها",link:"/content/start"},
    {svg:NewMessages,title:"درباره محصول",subtitle:"اهداف و مزایای محصول",link:"/content/about"},
    {svg:NewPropertyRequst,title:"پشتیبانی مشتریان",subtitle:"پشتیبانی آنلاین و مرکز تماس",link:"/content/buyers"},
    {svg:NewSupplements,title:"انجمن ها",subtitle:"موضوعات و کاربران",link:"/content/buyers"},
    {svg:NewPrimaryGoodRequest,title:"بلاگ",subtitle:"مقالات و تازه های سایت",link:"/content/buyers"}
]




const FullPage = styled.div`
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  position:relative;
  background-image:url('/images/Frame 85.png');
  background-repeat: no-repeat;
  background-position: bottom;

`
const Container = styled.div`
@media(max-width:760px){
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    top: 0;
    position: relative;
    width: 130%;
    height: 62%;
    padding-right:10rem;
  padding-left:10rem;
    ::-webkit-scrollbar {
  display: none;

}
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}
background:white;
width:65%;
height:90%;
position:absolute;
top:-10rem;
background: linear-gradient(180deg, #F2F7FF 14.76%, rgba(234, 234, 234, 0) 213.56%);
box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.2);
border-radius: 20px;
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items:center;
justify-content:center;
padding:5rem;
box-sizing:border-box;
`


const DashboardItem = styled.div`
position:relative;
transition:all 500ms;
    background-color:var(--bg-color-inside);
    width:20rem;
    height:20rem;
    min-height:80px;
    margin:10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
font-weight: 300;
font-size: 1.6rem;
line-height: 2.8rem;
background: #FFFFFF;
box-shadow: 0px 1px 12px 1px rgba(0, 0, 0, 0.2);
border-radius: 1.5rem;
color: var(--color-primery-1);
svg{
  filter:var(--svg-fillter) ;
}

z-index: 1;
&:hover {
  .title{
    color:white;
  }
    color:white;
    svg{
        path{
            fill:none;
            stroke:var(--svg-hover);
        }
    }
}


&::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height:100%;
  border-radius:1.5rem;
  background: linear-gradient(280.99deg, #240FA1 2.83%, #0B99F6 93.37%);
  z-index: -1;
  transition: opacity 0.2s linear;
  opacity: 0;
}
&:hover::before {
  opacity: 1;
}
`
const TitleContainer = styled.div`
    width:60%;
    display:grid;
    text-align:center;
    box-sizing:border-box;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #0064E5;
`
const SvgContainer = styled.div`
    svg{
        max-height:50px;
    }
    
`

function PannelSection(props){
  useEffect(()=>{
    document.getElementById('container').scrollLeft +=410;
  },[])
const MySvg = AddShopRequest
return(
  <FullPage>
 <Container id='container'>
 {
    Svg.map(item=>{
        let MySvg = item.svg;
        let index = Svg.indexOf(item);
        return( 
        <StyledTableLink key={index}  to={item.link}>
        <DashboardItem  key={index}>
            <SvgContainer>
                <MySvg/>
            </SvgContainer>
            <TitleContainer>
            <div className="title">{item.title}</div>
            </TitleContainer>
            <div style={{fontWeight:"400",fontSize:"1.2rem",lineHeight:"4rem",textAlign:"center",color:"#9C9C9C"}}>{item.subtitle}</div>
        </DashboardItem>
        </StyledTableLink>
        )
    })
    }

  
  </Container>   
  </FullPage>
)
}
export default PannelSection;
