import React, { useState,useEffect, useRef } from 'react';
import styled from  '@emotion/styled';
import SectionsPart from './SectionsPart';
import PannelSection from './PannelSection';
import MainSection from './MainSection';
import { sideMenuData } from '../Data.jsx';
import {FiChevronDown } from "react-icons/fi";
import Begin from '../Contents/ContentView';
import { cd, ContentData } from '../ContentData';
import TitleView from '../Contents/TitleView';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import Fotter from './Fotter';
import useWindowDimensions from '../Components/SizeDetector';



const FullPage = styled.div`
  width:100vw;
  background:white;
`
const ContetnSection = styled.div`
    box-sizing: border-box;
    @media (max-width:760px){
      padding: 4rem 3rem;
}
@media (min-width:760px){
 padding: 10rem 23vw 3rem 3vw
}

    text-align: right;
    font-size: 1.5rem;
    direction:rtl;
    position:relative;

`
const SideMenu = styled.div`
  width:20vw;
  
  background: #F8F8FA;
  position:absolute;
  right:0;
  top:0;
  bottom: 0;
  padding: 13rem 3rem 0 0;
  box-sizing: border-box;
  text-align: right;
  overflow:auto;
  font-size:1.5rem;
  direction:rtl;
  ::-webkit-scrollbar-track
{
	background-color: transparent;
  direction:ltr;
}
::-webkit-scrollbar
{
	width: 6px;
	background-color: transparent;
}
::-webkit-scrollbar-thumb
{
	background-color: var(--bg-color-nav);
  border-radius:10px;
}

`

function ContentPage(props){
  
  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
    setActiveContent(x["*"])

  },[activeContent])
  const {width,height} = useWindowDimensions()
  console.log(height,width)

  const x = useParams()["*"]
  const findContent = (name)=>{
    for(let item of sideMenuData){
      if(item.type==="single"){
        if(item.name===name)
          return item.view
      }
      if(item.type==="multiple"){
        if(name===item.name){
          let data = []
          for(let product of item.content){
            data.push(ContentData(product.name))
          }
          return <TitleView name={item.name} title={item.title} data={data} link={setActiveContent}/>
        }
      else
      for(let product of item.content)
      if(product.name===name)
        return product.view
      }

    }
  }
  const [activeContent,setActiveContent] = useState(x) 
  console.log(activeContent)

  const linkContent = (name) =>{
    setActiveContent(name)
    window.scrollTo({top: 0, behavior: 'smooth'});
    
  }
  console.log()


  return(
  <>
    <FullPage>
      <ContetnSection isDesk={width>760}> 
        {width>760?
              <SideMenu>
              {sideMenuData.map((item)=><ContentLink
                activeContent={x}
                onClick={linkContent}
                title={item.title}
                multiple={item.type==="multiple"}
                submenus={item.content}
                name={item.name}
                   />)}
            </SideMenu>
        :
        null
      }

          {findContent(x)}

      </ContetnSection>
      <Fotter/>
    </FullPage>
  </>
)

}
export default ContentPage;


export const ContentLink = ({closeNav,activeContent,title,onClick,multiple=false,isOpen=false,submenus,name,somewhereelse=false})=>{
const [activeSub,setActiveSub] = useState(false)
if(!multiple){
  return(
    <Menu somewhereelse to={name} active={activeContent===name} onClick={()=>{onClick(name);closeNav(false)}}>{title}</Menu>
  )
}
if(multiple){
  return(
      <>
      <Menu somewhereelse  to={name} active={activeContent===name} onClick={()=>{onClick(name);setActiveSub(!activeSub)}} >
      <FiChevronDown  onClick={()=>setActiveSub(!activeSub)}/>
      {title}
        </Menu>
      <SubMenu somewhereelse active={activeSub}>
      {submenus.map((item)=>{
        return(<Menu somewhereelse to={somewhereelse?"/content/"+item.name:item.name}><SideUl somewhereelse onClick={()=>{onClick(name);closeNav(false);setActiveSub(!activeSub)}} active={activeContent===item.name}>{item.title}</SideUl></Menu>)
      })}
      </SubMenu>
      </>
  )
}
}
const Menu = styled(NavLink)`
  text-decoration:none;

  @media (max-width:760px){
    :visited,:link{
    color:white;

  }
  }

  @media (min-width:760px){
    :visited,:link{
    color:${props => (props.active? "#2b3cf6" : "#414141")};

  }
  }
  

  display: flex;
  flex-direction: row;
  align-items:center;
  column-gap:.5rem;
  padding:1rem;
  box-sizing:border-box;
  cursor:pointer;
  transition:all 300ms;
  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`
const SubMenu = styled.div`
overflow: hidden;
/* transition:${props => (props.active? "all 500ms" : "all 500ms")}; */
opacity:${props => (props.active? "1" : "0")};
max-height:${props => (props.active? "500px" : "0")};
padding-right:5rem;
`;

const SideUl = styled.li`
  padding:0.5rem 0;
  direction:rtl;
  cursor:pointer;
  transition:all 300ms;
  color:${props => (props.active? "#2132f3" : "")};
  :hover{
    text-shadow: 0px 0px 3px #a5a5a5;
  }
`

const first = {
  type:"single",
  name:`begin`,
  title:`راهنمای  مربوط به پنل فروشندگان پلتفرم لوکسین لوکس`,
  text_1:`پلتفرم لوکسین لوکس به هر فروشنده منحصرا پنل فروشگاهی اختصاص می دهد که فروشندگان می توانند با این پنل تمام کارهای خود از قبیل افزودن محصولات، لیست سفارشات مشتریان، پیامها، نظرات و پرسش و پاسخ خریداران در مورد محصولات ، تراکنشهای مالی و گزارشهای متنوع مشاهده و مدیریت نمایند.`,
  img_1:`begin (1).jpg`,
  text_2:`برای ورود به پنل فروشگاه از طریق صفحه کاربری خود، از منوی باز شونده دکمه پنل فروشگاه را کلیک و پس از باز شدن لیست فروشگاهها، با کلیک بر روی فروشگاه مورد نظر، وارد پنل مدیریت فروشگاه خود خواهید شد.`,
  img_2:`begin (2).jpg`,
  img_3:`begin (3).jpg`,
  text_4:`پس از ورود، پنل مدیریت فروشگاه برای شما نشان داده خواهد شد.`,
  img_4:`begin (4).jpg`,
  text_5:`در این صفحه تمامی اطلاعات مربوط به مدیریت فروشگاه قابل مشاهده می باشد.
  گزارش مهمترین خریداران، دیدگاههای اخیر کاربران، بازدیدهای اخیر و کیف پول نیز در این صفحه نمایش داده شده که با زدن دکمه مشاهده در زیر هر بخش، گزارش آن قابل دسترسی خواهد بود. 
  همچنین در بالای صفحه، اطلاع رسانی های  مهم سیستم از جمله تعداد پیامهای جدید، تعداد سفارشات جدید، موجودیهای کالا های به حداقل رسیده ، الحاقیه های جدید و تعداد مرجوعی های جدید قابل مشاهده می باشدکه با کلیک بر روی هر کدام از موارد ذکر شده به صفحه همان موارد ارجاع داده می شوید.
  `,
  img_5:`begin (5).jpg`,
  }

  const ContentContainer = ({data})=>{
      let arr = []
      for(let item in data) {
        arr.push(item);
      }
    return(
        <div>
          {    
            arr.map((item)=><div>{item}</div>)
          }
        </div>
    )
  }



const Hamburger = ()=>{
const [name,setName] = useState("menu btn")
return(
  <XOS size="2">
<div className={name} onClick={name==="menu btn"?()=>setName("menu open btn"):()=>setName("menu btn")} data-menu="10">
<div class="icon"></div>
</div>
</XOS>
)
}
const Mapping = styled.div`
  width :100%;
  display:flex;
  justify-content:space-between;
  padding:2rem 0;
  box-sizing:border-box;
`
const MappingItem = styled(Link)` 
  cursor:pointer;
  color:${props=>props.active?"#3e3e3e":"#3e3e3e34e"}
`
const XOS = styled.div`
--original-width:${props => (props.size+"rem")};
--original-height:${props => (props.size/7.5+"rem")};
--third:${props => (props.size/3+"rem")};
--third-min:${props => (-props.size/3+"rem")};
--tenth:${props => (props.size/10+"rem")};
--tenth-min:${props => (-props.size/10+"rem")};

width:100%;
height:100%;
.btn {
  position: absolute;
  width:${props => (props.size+"rem")};
  height: ${props => (props.size+"rem")};;
  top: 300px;
  left: 120px;
  transition-duration: 0.5s;
}
.btn .icon {
  transition-duration: 0.5s;
  position: absolute;
  height: ${props => (props.size/7.5+"rem")};
  width: ${props => (props.size+"rem")};
  top: 30px;
  background-color: #212121;
}
.btn .icon:before {
  transition-duration: 0.5s;
  position: absolute;
  height: ${props => (props.size/7.5+"rem")};
  width: ${props => (props.size+"rem")};
  background-color: #212121;
  content: "";
  top: -20px;
}
.btn .icon:after {
  transition-duration: 0.5s;
  position: absolute;
  height: ${props => (props.size/7.5+"rem")};
  width: ${props => (props.size+"rem")};
  background-color: #212121;
  content: "";
  top: 20px;
}
.btn.open .icon {
  transition-duration: 0.5s;
  transform: rotateZ(-180deg);
}
.btn.open .icon:before {
  transform: rotateZ(-45deg) scaleX(0.75) translate(var(--third-min), var(--tenth-min));
}
.btn.open .icon:after {
  transform: rotateZ(45deg) scaleX(0.75) translate(var(--third-min), var(--tenth));
}
.btn:hover {
  cursor: pointer;
}

  `