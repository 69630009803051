import React from 'react';
import styled from  '@emotion/styled';

const LoadingCss = styled.div`
/* loading animation*/
display: flex;
justify-content: center;
margin-top: 1rem;
.lds-ellipsis {
  margin: 0 auto;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 20px;
}
.lds-ellipsis div {
  position: absolute;
  top: 3.5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--primary-1);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-of-type(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-of-type(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-of-type(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-of-type(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0) translate(-24px, 0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0) translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

`;

export const Loading = ({small}) => {
    let style = '';
    if(small){
      style = 'small-top';
    }
    return (
        <LoadingCss className={style}>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </LoadingCss>
        
    );
}