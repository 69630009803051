import './App.css';
import styled from  '@emotion/styled';
import {BrowserRouter,Routes,Route,Link}
from "react-router-dom";import NavbarMenu from './Components/NavbarMenu';
import HomePage from './new/HomePage';
import ContentPage from './new/ContentPage';
import Fotter from './new/Fotter';
import SearchPage from './new/SearchPage';

const Page = styled.div`
  --bg-color:${props => (props.islight===true ? "#22262B" : "#F7F7FA")};
  --bg-color-nav:${props => (props.islight===true ? "#383C41" : "#003A92")};
  --bg-color-inside:${props => (props.islight===true ? "#383C41" : "#FFFFFF")};
  --bg-color-inside-secondary:${props => (props.islight===true ? "#59595b" : "#EFEFF5")};
  --color-primery-1:${props => (props.islight===true ? "#FFFFFF":"#010133")};
  --color-primery-2:${props => (props.islight===true ? "#FFFFFF":"#0D122B")};
  --color-primery-3:${props => (props.islight===true ? "#FFFFFF":"#2D2D7D")};
  --color-primery-4:${props => (props.islight===true ? "#FFFFFF":"#221DAA")};
  --color-primery-5:${props => (props.islight===true ? "#FFFFFF":"#0E8AED")};
  --svg-fillter:  ${props => (props.islight ? "grayscale(100%) invert(100%)":"grayscale(0%) invert(0%)")};
  --svg-hover:  ${props => (props.islight ? "black":"white")};
  --black-and-white:  ${props => (props.islight ? "white":"black")};
  --white-and-black:  ${props => (props.islight ? "black":"white")};
  --hbg-fill:  ${props => (props.islight ? "fill:none":"none")};
  --hbg-stroke:  ${props => (props.islight ? "white":"")};
 
    display:grid;
    background-color:var(--bg-color);
    transition:all 500ms;
    @media (max-width: 765px) {
      min-height:100vh;
      height:fit-content;
      grid-template-columns: auto;
    };
    @media (min-width: 765px) {
      height: 100%;
      grid-template-columns: auto;
      grid-template-rows:auto auto;
    }
`;

const Content = styled.div`
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
`
const ProfileAndToggle = styled.div`
.image{
  display:none;
}
width:100%;
padding:10px;
display:flex;
flex-direction:row-reverse;
align-items:center;
justify-content:space-between;


@media (min-width:766px){
width:100%;
justify-self:center;
align-self:center;
flex-direction:row-reverse;
justify-content:space-between;
border-bottom:0.25px solid rgba(85, 85, 85,0.2);
.image{
  display:block;
}
img{
  width:20%;
  height:auto;
}
}

`;
const PageHeader = styled.div`
  display:flex;
  flex-direction:column;
  width:95%;
  align-items:center;
  justify-content:center;
  align-self:center;
`
const TogglesContainer = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
`
const LoginError = styled.div`
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  .error{
    transform:scale(2);
    display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  }
`

function App() {


  return (
    <div  className="App">
      <Page>
        <BrowserRouter path="/"  basename={'/'}>
          <NavbarMenu/>
          <Content>


              <Routes>
              <Route exact path='/' element={<HomePage/>}/>
              <Route path="content*" element={<ContentPage/>}/>
              <Route path="search*" element={<SearchPage/>}/>

              </Routes>
          </Content>
        </BrowserRouter>
      </Page>
    </div>
  );
}

export default App;
